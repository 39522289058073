import React from "react"

import { DateField, Show, SimpleShowLayout, TextField } from "react-admin"

const NotificationShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Nombre de la campaña" />
      <DateField source="date" label="Fecha de la campaña" />
      <TextField source="message" label="Mensaje" />
      <TextField source="link" label="Url notificación" />
    </SimpleShowLayout>
  </Show>
)
export default NotificationShow
