import React from 'react';

import {
  BooleanInput,
  Edit,
  ImageField,
  ImageInput,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const validateTitle = [required(), minLength(2), maxLength(200)];
const BannerEdit = (props) => (
  <Edit {...props} title="Editar información de banners">
    <SimpleForm>
      <TextInput
        source="title"
        label="Título"
        fullWidth={true}
        validate={validateTitle}
      />
      <TextInput source="description" label="Descripción" fullWidth={true}   />
      <ImageInput source="image" label="Imagen" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="link" label="Link" fullWidth={true} type="url"  />
      <TextInput source="internalLink" label="Link interno del app" fullWidth={true} type="text"  name={'internalLink'}/>
      <BooleanInput source="active" label="Activo" />
      <TextInput source="priority" label="Prioridad" color="secondary" variant="outlined"/>
      <BooleanInput source="requireAuth" label="Requiere Autenticación" />

    </SimpleForm>
  </Edit>
);
export default BannerEdit;
