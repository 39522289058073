import React from 'react';

import { FaProductHunt } from 'react-icons/fa';

const icon = () => {
  return (
    <div>
      <FaProductHunt size={24}></FaProductHunt>
    </div>
  )
}
export default icon
