import React from "react";

import {
  SimpleForm,
  TextInput,
  NumberInput, Edit, Labeled, ReferenceArrayInput, SelectArrayInput
} from 'react-admin';

const cdirDepartmentEdit = (props) => (
  <Edit title="Editar información de departamentos" {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Nombre"
        fullWidth={true}
        name={"name"}
      />
      <TextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        name={"description"}
      />
      <NumberInput
        source="level"
        label="Nivel"
        fullWidth={true}
        name={"level"}
      />
      <Labeled label="Equipo">

        <ReferenceArrayInput
          source="team"
          reference="cdir/team"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

      </Labeled>
    </SimpleForm>
  </Edit>
)

export default cdirDepartmentEdit;