import React from "react";

import {
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
} from "react-admin";

const CreateActivitiesTypes = (props) => (
  <Create title="Crear Tipos de Actividades" {...props}>
    <SimpleForm>
        <SelectInput
            source="name"
            color="secondary"
            variant="outlined"
            label="Nombre"
            fullWidth={true}
            choices={[
                { id: "charla", name: "Charla" },
                { id: "taller", name: "Taller" },
                { id: "exposicion", name: "Exposicion" },
                { id: "recorrido", name: "Recorrido" },
                { id: "apertura espacios", name: "Apertura de Espacios" },
            ]}
            required
        />
      <TextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        name={"description"}
        required
      />
    </SimpleForm>
  </Create>
);
export default CreateActivitiesTypes;
