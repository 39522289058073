import React from "react";
import { BiCategory } from "react-icons/bi";
const icon = () => {
  return (
    <div>
      <BiCategory size={24}></BiCategory>
    </div>
  );
};
export default icon;
