import React from 'react';

import {
  AutocompleteInput,
  Edit,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const OrganizationEdit = (props) => (
  <Edit {...props} title="Editando acceso a  usuario a una organización">
    <SimpleForm>
      <Labeled label="Organización">
        <ReferenceInput
          source="organization"
          reference="organization"
          label="Organización"
          perPage={100000}
          sort={{ field: 'name', order: 'ASC' }}
          color="secondary"
          variant="outlined"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Labeled>
      <Labeled label="Usuario">
        <ReferenceInput
          source="user"
          reference="users"
          label="Usuario"
          perPage={100000}
          sort={{ field: 'profile.identifier', order: 'ASC' }}
          color="secondary"
          variant="outlined"
        >
          <AutocompleteInput optionText="profile.identifier" />
        </ReferenceInput>
      </Labeled>
      <TextInput source="access" label="Acceso" defaultValue={'RW'} />
    </SimpleForm>
  </Edit>
)
export default OrganizationEdit
