import React from 'react';

import {
  BooleanInput,
  Edit,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const validateTitle = [required(), minLength(2), maxLength(100)];

const HelpCommunityEdit = (props) => (
  <Edit title="Editar ayuda a la comunidad." {...props}>
       <SimpleForm>
      <TextInput
        source="name"
        label="Nombre de la ayuda"
        fullWidth={true}
        validate={validateTitle}
      />
      <TextInput source="description" label="Descripción" fullWidth={true} required  />
      <BooleanInput source="active" label="Activo" />
      <BooleanInput source="isWebview" label="Es webview" />
      <TextInput source="url" label="Url" fullWidth={true}  type="url" required  />
    </SimpleForm>
  </Edit>
);
export default HelpCommunityEdit;
