import icon from "./icon";
import create from "./create";
import list from "./list";
import edit from "./edit";
import show from "./show";

export default {
  create: create,
  list: list,
  edit: edit,
  icon: icon,
  show: show,
  options: { label: "Categorias Descuentos" },
};
