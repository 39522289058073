import React from 'react'
import {
  TextField,
  Show,
  SimpleShowLayout,
} from 'react-admin'

const DepartmentShow = (props) => (
  <Show title="Mostrando información del departamento" {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Nombre del departamento" />
      <TextField source="description" label="Descripción" />
      <TextField source="urlKey" label="URL Key" />
      <TextField source="level" label="Nivel"/>
    </SimpleShowLayout>
  </Show>
)

export default DepartmentShow