import React from "react"
import { GrDocumentText } from "react-icons/gr"
const icon = () => {
  return (
    <div>
      <GrDocumentText size={24}></GrDocumentText>
    </div>
  )
}
export default icon
