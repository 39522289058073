import React from "react"
import { FiMap } from "react-icons/fi"
const icon = () => {
  return (
    <div>
      <FiMap size={24}></FiMap>
    </div>
  )
}
export default icon
