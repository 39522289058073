import React from "react"
import { BsFillImageFill } from "react-icons/bs"
const icon = () => {
  return (
    <div>
      <BsFillImageFill size={24}></BsFillImageFill>
    </div>
  )
}
export default icon
