import React from "react";

import {
  Datagrid,
  EditButton,
  Filter,
  Labeled,
  List,
  ReferenceArrayField,
  SearchInput,
  SelectInput,
  TextField,
  SingleFieldList,
  ChipField,
} from "react-admin";

const OrganizationFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
    <Labeled label="Tipo de Organización">
      <SelectInput
        source="type"
        label=""
        choices={[
          { id: "municipal", name: "Municipal" },
          { id: "commerce", name: "Comercio" },
        ]}
      />
    </Labeled>
  </Filter>
);

const OrganizationList = (props) => (
  <List
    {...props}
    title="Listado de Organizaciones"
    filters={<OrganizationFilter></OrganizationFilter>}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nombre" />
      <TextField source="description" label="descripción" />
      <ReferenceArrayField
        label="Categoria"
        source="category"
        reference="organizationCategories"
      >
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="authRole" label="Role" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
);
export default OrganizationList;
