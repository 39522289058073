import React from 'react';

import {
  AutocompleteInput,
  Create,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';

const OrganizationCreate = (props) => {
  
  const matchSuggestion = (filterValue, choice) => {
    try{
    var response = choice.emails[0].address.match(filterValue) ; 

    }catch(e){
      return  ""

    }
    return response;
}


const inputText = (record) => `${record.emails[0].address}`;

  const EmailField = () => {
      const record = useRecordContext();
      var email = record.emails[0].address; 
      return <span>{email} </span>;
  }
  
  return(
  <Create title="Asignar usuario a organización" {...props}>
    <SimpleForm>
   
    <Labeled label="Organización">
          <ReferenceInput
            source="organization"
            reference="organization"
            label="Organización"
            perPage={100000}
            sort={{ field: 'name', order: 'ASC' }}
            color="secondary"
            variant="outlined"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Labeled>      
        <Labeled label="Usuario">
          <ReferenceInput
            source="user"
            reference="users"
            fullWidth
            label="Usuario"
            perPage={100000}

            sort={{ field: 'profile.identifier', order: 'ASC' }}
            color="secondary"
            variant="outlined"

          >    
          <AutocompleteInput optionText={<EmailField />} matchSuggestion={matchSuggestion} inputText={inputText} fullWidth/>

          </ReferenceInput>


        </Labeled>     
        <TextInput source="access" label="Acceso" defaultValue={"RW"}  />


    </SimpleForm>
  </Create>
)};
export default OrganizationCreate;
