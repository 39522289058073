import React from "react";
import { BsFillPlayFill } from "react-icons/bs";
const icon = () => {
  return (
    <div>
      <BsFillPlayFill size={24}></BsFillPlayFill>
    </div>
  );
};
export default icon;
