import InputSearchPlace from "components/fields/inputSearchPlace";
import React from "react";

import {
  ArrayInput,
  BooleanInput,
  DateInput,
  Edit,
  FormDataConsumer,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
    SaveButton,

} from "react-admin";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

const CustomToolbar = props => (
    <>
      <SaveButton/>
      {props.children}
    </>
);

const UserEdit = (props) => (
  <Edit title="Editando Usuario" {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Card variant="outlined" sx={{ minWidth: 1100 }}>
        <CardHeader
          title="Información general del usuario"
        //subheader="September 14, 2016"
        />
        <CardContent>
          <TextInput
            source="profile.firstName"
            label="Nombre"
            fullWidth={true}
            color="secondary"
            variant="outlined"
          />
          <TextInput
            source="profile.lastName"
            label="Apellido"
            fullWidth={true}
            color="secondary"
            variant="outlined"
          />
          <TextInput
            label="Rut"
            source="profile.identifier"
            fullWidth={true}
            color="secondary"
            variant="outlined"
          />
          <DateInput
            source="profile.birthdate"
            label="Fecha de Nacimiento"
            color="secondary"
            variant="outlined"
          />
          <br />

          <SelectInput
            source="profile.gender"
            color="secondary"
            variant="outlined"
            choices={[
              { id: "hombre", name: "Masculino" },
              { id: "mujer", name: "Femenino" }
            ]}
            label="Género"
          />
        </CardContent>
      </Card>
      <br />
      <br />

      <Card variant="outlined" sx={{ minWidth: 1100 }}>
        <CardHeader
          title="Información de contacto"
        //subheader="September 14, 2016"
        />
        <CardContent>
          <TextInput
            label="Télefono "
            source="profile.phone.mobilePhone"
            color="secondary"
            variant="outlined"
          />

          <ArrayInput source="emails" fullWidth={true} label="Emails">
            <SimpleFormIterator>
              <TextInput
                source="address"
                label="Email"
                color="secondary"
                variant="outlined"
              />
              <BooleanInput
                source="verified"
                label="Verificado"
                color="secondary"
                variant="outlined"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </CardContent>
      </Card>
      <br />
      <br />

      <Card variant="outlined" sx={{ minWidth: 1100 }}>
        <CardHeader
          title="Dirección"
        //subheader="September 14, 2016"
        />
        <CardContent>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <InputSearchPlace
                  name="profile.address"
                  address={formData.address}
                  {...rest}
                ></InputSearchPlace>
              );
            }}
          </FormDataConsumer>
        </CardContent>
      </Card>
      <br />
      <br />

      <Card variant="outlined" sx={{ minWidth: 1100 }}>
        <CardHeader
          title="Información Administrativa"
        //subheader="September 14, 2016"
        />
        <CardContent>
          <SelectArrayInput
            label="Roles"
            source="roles"
            choices={[
              { id: "admin", name: "Admin" },
              { id: "admin-tvecino", name: "Admin tarjeta vecino" },
              { id: "admin-municipal", name: "admin-municipal" },
              { id: "admin-cdir", name: "admin-cdir" },
              { id: "commerce", name: "Comercio" }

            ]}
          />
        </CardContent>
      </Card>
    </SimpleForm>
  </Edit>
);
export default UserEdit;
