import React from "react";

import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  ImageField,
  ImageInput,
  Labeled,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import InputSearchPlace from "../../components/fields/inputSearchPlace";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const OrganizationCreate = (props) => (
  <Create title="Crear Organización" {...props}>
    <SimpleForm>
      <Card variant="outlined" sx={{ minWidth: "100%" }}>
        <CardHeader title="Información de la organización" />
        <CardContent>
          <Labeled label="Nombre de la organización" fullWidth>
            <TextInput source="name" label="" fullWidth required />
          </Labeled>
          <Labeled label="Descripción de la organización" fullWidth>
            <TextInput
              source="description"
              label=""
              fullWidth
              multiline={true}
            />
          </Labeled>
          <Labeled label="Tipo de Organización">
            <SelectInput
              source="type"
              label=""
              required
              choices={[
                { id: "municipal", name: "Municipal" },
                { id: "commerce", name: "Comercio" },
              ]}
            />
          </Labeled>
          <br></br>

          <Labeled label="Categoria">
            <ReferenceArrayInput
              source="category"
              reference="organizationCategories"
            >
              <SelectArrayInput optionText="title" />
            </ReferenceArrayInput>
          </Labeled>

          <Labeled label="Logo" fullWidth>
            <ImageInput source="logo" label=" " accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Labeled>
        </CardContent>
      </Card>

      <br></br>

      <Card variant="outlined" sx={{ minWidth: "100%" }}>
        <CardHeader title="Dirección e información de contacto de la organización" />
        <CardContent>
          <ArrayInput
            source="contactInformation"
            label="Información de contacto"
          >
            <SimpleFormIterator>
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource, // A function to get the valid source inside an ArrayInput
                  ...rest
                }) => {
                  const source = getSource(scopedFormData);

                  const re = source.replace(".undefined", ".address");
                  const re2 = re.replace(".[object Object]", ".address");
                  const other = source.replace(".undefined", "");
                  const other2 = other.replace(".[object Object]", "");

                  return re2 !== null ? (
                    <div>
                      <InputSearchPlace
                        name={re2}
                        address={scopedFormData}
                        {...rest}
                      ></InputSearchPlace>
                      <Labeled
                        label={"Código de area"}
                        className={"custom-label-form"}
                      >
                        <TextInput
                          source={other2 + ".phone.areaCode"}
                          label=""
                          type={"number"}
                        />
                      </Labeled>
                      <Labeled
                        label={"Número de teléfono"}
                        className={"custom-label-form"}
                      >
                        <TextInput
                          source={other2 + ".phone.number"}
                          label=""
                          type={"text"}
                        />
                      </Labeled>
                      <br />
                      <Labeled label={"Número de celular"}>
                        <TextInput
                          source={other2 + ".phone.mobilePhone"}
                          label=""
                          type={"text"}
                        />
                      </Labeled>
                      <br></br>

                      <Labeled label={"Correo electrónico"}>
                        <TextInput
                          source={other2 + ".email"}
                          label=""
                          type={"email"}
                        />
                      </Labeled>
                    </div>
                  ) : null;
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </CardContent>
      </Card>

      <br />

      <Card variant="outlined" sx={{ minWidth: "100%" }}>
        <CardHeader title="Permisos de la organización " />
        <CardContent>
          <Labeled label="Rol">
            <SelectInput
              source="authRole"
              label=""
              required
              choices={[
                { id: "admin-municipal", name: "Admin Municipal" },
                { id: "commerce", name: "Comercio" },
                { id: "admin-cdir", name: "Admin Cdir " },
                { id: "admin-cultura", name: "Admin Cultura" },
              ]}
            />
          </Labeled>
          <br></br>
          <Labeled label="Activo">
            <BooleanInput source="active" label="Activo" />
          </Labeled>
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);
export default OrganizationCreate;
