import InputSearchPlaceCultural from "components/fields/inputSearchPlaceCultural";
import React from "react";

import {
  Create,
  FormDataConsumer, ImageField, ImageInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin';

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";


const SenderCreate = (props) => (
  <Create title="Crear Remitente" {...props}>
    <SimpleForm>
      <Card variant="outlined" sx={{ minWidth: 1100 }}>
        <CardHeader
          title="Información general del remitente"
        />
        <CardContent>
          <TextInput
            source="name"
            label="Nombre"
            fullWidth={true}
            color="secondary"
            variant="outlined"
          />
          <TextInput
            source="description"
            label="Descripción"
            fullWidth={true}
            color="secondary"
            variant="outlined"
          />
          <ImageInput source='logo' label='Logo' accept='image/*'>
            <ImageField source='src' title='title' />
          </ImageInput>
          <SelectInput
            source="socialMedia"
            label="Redes Sociales"
            fullWidth={true}
            color="secondary"
            variant="outlined"
            choices={[
              { id: 'facebook', name: 'Facebook' },
              { id: 'instagram', name: 'Instagram' },
              { id: 'twitter', name: 'Twitter' },
            ]}
          />
          <TextInput
            source="contactInformation.phone"
            label="Teléfono"
            fullWidth={true}
            color="secondary"
            variant="outlined"
          />
          <TextInput
            source="contactInformation.email"
            label="Email"
            fullWidth={true}
            color="secondary"
            variant="outlined"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <InputSearchPlaceCultural
                  name="contactInformation.address"
                  address={formData.address}
                  {...rest}
                ></InputSearchPlaceCultural>
              );
            }}
          </FormDataConsumer>
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);

export default SenderCreate;