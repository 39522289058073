import React from "react";

import {
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";

const EditDistrict = (props) => (
  <Edit title="Editar Comuna" {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nombre" fullWidth={true} name={"name"} required />
      <TextInput
          source="description"
          label="Descripción"
          fullWidth={true}
          name={"description"}
          required
      />
    </SimpleForm>
  </Edit>
);
export default EditDistrict;
