import React from 'react';
import Icofont from "react-icofont";

const icon = () => {
  return (
    <div>
      <Icofont icon='icofont-newspaper' className='icon' spin="false"></Icofont>
    </div>
  )
}

export default icon;