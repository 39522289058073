import React from 'react'
import {
  TextField,
  Show,
  SimpleShowLayout,
} from 'react-admin'

const CdirProjectsShow = (props) => (
  <Show title="Mostrando información del proyectos cdir" {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Nombre del departamento" />
      <TextField source="description" label="Descripción" />
      <TextField source="urlKey" label="URL Key" />
    </SimpleShowLayout>
  </Show>
)

export default CdirProjectsShow