import { regex } from 'react-admin';

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
 const dateParseRegex = /(\d{4})-(\d{2})-(\d{2})/;
  
  const convertDateToString = value => {
      // value is a `Date` object
      if (!(value instanceof Date) || isNaN(value.getDate())) return '';
      const pad = '00';
      const yyyy = value.getFullYear().toString();
      const MM = (value.getMonth() + 1).toString();
      const dd = value.getDate().toString();
      return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
  };
  
  const dateFormatter = value => {
      // null, undefined and empty string values should not go through dateFormatter
      // otherwise, it returns undefined and will make the input an uncontrolled one.

   try {
        var splitarray = [];
        splitarray= value.split("T");  
        if (dateFormatRegex.test(splitarray[0])) return splitarray[0];
        if (value == null || value === '') return '';
        if (value instanceof Date) return convertDateToString(value);
        if (dateFormatRegex.test(value)) return value;
    
        return convertDateToString(new Date(value));
        
   } catch (error) {
    
   
  

      if (value == null || value === '') return '';
      if (value instanceof Date) return convertDateToString(value);
      // Valid dates should not be converted
      if (dateFormatRegex.test(value)) return value;
  
      return convertDateToString(new Date(value));
      }
  };
  
  const dateParser = value => {
      //value is a string of "YYYY-MM-DD" format
      console.log(value);
      const match = dateParseRegex.exec(value);
      if (match === null) return;
      // @ts-ignore
      const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
      if (isNaN(d.getDate())) return;
      return d;
  };



  const validateDate = [regex(dateFormatRegex)];

export { dateFormatter, dateParser, validateDate };