import React from "react";
import { MdOutlineCategory } from "react-icons/md";
const icon = () => {
  return (
    <div>
      <MdOutlineCategory size={24}></MdOutlineCategory>
    </div>
  );
};
export default icon;
