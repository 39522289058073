import InputSearchPlace from "components/fields/inputSearchPlace";
import React from "react";

import {
  Datagrid, EditButton,
  List,
  TextField,
} from 'react-admin';


const SenderList = (props) => (
  <List title="Remitentes" {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nombre" />
      <TextField source="description" label="Descripción" />
      <TextField source="socialMedia" label="Redes Sociales" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
)

export default SenderList