import React from "react"
import { FcReading } from "react-icons/fc"
const icon = () => {
  return (
    <div>
      <FcReading size={24}></FcReading>
    </div>
  )
}
export default icon
