import React from "react";

import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceArrayField,
  SearchInput,
  TextField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { getST } from "provider/Storage";

const DiscountFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
  </Filter>
);

const DiscuountList = (props) => {
  const role = getST("role");

  return (
    <List
      {...props}
      title="Listado de descuentos"
      filters={<DiscountFilter></DiscountFilter>}
    >
      <Datagrid rowClick="edit">
        {role !== "commerce" ? (
          <ReferenceField
            source="organization"
            reference="organization"
            label="Comercio"
          >
            <TextField source="name" />
          </ReferenceField>
        ) : null}
        <TextField source="title" label="Título del beneficio/descuento" />
        <TextField
          source="description"
          label="Descripción del beneficio/descuento"
        />
        <ReferenceArrayField
          label="Categoria"
          reference="discountCategories"
          source="category"
        >
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <EditButton label="Editar" />
      </Datagrid>
    </List>
  );
};
export default DiscuountList;
