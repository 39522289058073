import React from "react";

import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  Labeled,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import InputSearchPlaceCultural from "../../../components/fields/inputSearchPlaceCultural";
import {RichTextInput} from "ra-input-rich-text";

const validateForm = (values) => {
  const { address } = values

  const errors = {};
  if (!address || !address.streetName || !address.city) {
    errors.address = "Debes seleccionar un lugar de interes";
  }
  return errors;
};
const EditInterestPlaces = (props) => (
  <Edit title="Editar Lugares de Interes" {...props}>
    <SimpleForm validate={validateForm}>
      <Card variant="outlined" sx={{ minWidth: 1600 }}>
        <CardHeader
          title="Informacion Obligatoria"
        />
        <CardContent>
          <TextInput source="name" label="Nombre" fullWidth={true} name={"name"} required/>
          <RichTextInput
            source="description"
            label="Descripción"
            fullWidth={true}
            name={"description"}
            required
            multiline
          />
          <RichTextInput
            source="summary"
            label="Resumen"
            fullWidth={true}
            name={"summary"}
            required
            multiline
          />
          <TextInput
            source="urlKey"
            label="Url Key"
            fullWidth={true}
            name={"urlKey"}
            defaultValue={""}
          />
        </CardContent>
      </Card>

      <br />

      <Card>
        <CardHeader variant="outlined" sx={{ minWidth: 1600 }}
          title="Url"
        />
        <CardContent>
          <ArrayInput source="links" label="Links" fullWidth defaultValue={[]}>
            <SimpleFormIterator fullWidth>
              <TextInput source="url" label="URL" fullWidth/>
            </SimpleFormIterator>
          </ArrayInput>
        </CardContent>
      </Card>

      <br/>

      <Card variant="outlined" sx={{ minWidth: 1600 }}>
        <CardHeader
          title="Imagenes"
        />
        <CardContent>
          <Labeled label="Icono" fullWidth>
            <>
              <TextInput source="icon.title" label="Titulo" fullWidth />
              <TextInput source="icon.url" label="Url" fullWidth />
            </>
          </Labeled>

          <br/>
          <Labeled label="Imagen de portada" fullWidth>
            <>
              <TextInput source="coverImage.title" label="Titulo" fullWidth />
              <TextInput source="coverImage.url" label="Url" fullWidth />
            </>
          </Labeled>

          <br/>

          <Labeled label="Estampillas" fullWidth>
            <>
              <TextInput source="stamps.title" label="Titulo" fullWidth />
              <TextInput source="stamps.url" label="Url" fullWidth />
              <TextInput source="stamps.lockTitle" label="Titulo Estampilla Bloqueada" fullWidth />
              <TextInput source="stamps.lockUrl" label="Url Estampilla Bloqueada" fullWidth />
            </>
          </Labeled>

          <br/>
          <ArrayInput source="image" label="Imagenes" fullWidth defaultValue={[]}>
            <SimpleFormIterator fullWidth>
              <TextInput source="title" label="Titulo" fullWidth/>
              <TextInput source="url" label="URL" fullWidth/>
            </SimpleFormIterator>
          </ArrayInput>

        </CardContent>
      </Card>

      <br/>
      <Card variant="outlined" sx={{ minWidth: 1400 }}>
        <CardHeader title="Ubicación del Lugar de Interes" />
        <CardContent>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <InputSearchPlaceCultural
                name="address"
                address={formData.address}
                {...rest}
              ></InputSearchPlaceCultural>
            )}
          </FormDataConsumer>
        </CardContent>
      </Card>
    </SimpleForm>
  </Edit>
);
export default EditInterestPlaces;
