import React, { useState } from "react";

import {
  ArrayInput,
  Create,
  Labeled,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { dateFormatter, dateParser } from "../../../helpers/date";

import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {RichTextInput} from "ra-input-rich-text";

const CreateActivities = (props) => {

  const [isAddressEnabled, setIsAddressEnabled] = useState(true);
  const handleInterestPlaces = (event) => {
    const emailValue = event.target.value;
    if (emailValue !== ""){
      setIsAddressEnabled(false); // Convierte el valor a booleano
    } else {
      setIsAddressEnabled(true)
    }
  };

  return (
      <Create title="Crear información de actividades patrimonio" {...props}>
        <SimpleForm>
          <Card variant="outlined" sx={{ minWidth: 1600 }}>
            <CardHeader
                title="Informacion"
            />
            <CardContent>
              <TextInput
                  source="title"
                  label="Título"
                  fullWidth={true}
                  name={"title"}
                  required
              />
              <TextInput
                  source="organizedBy"
                  label="Organizador"
                  fullWidth={true}
                  name={"organizedBy"}
                  required
              />
              <TextInput
                  source="url"
                  label="Url"
                  type="url"
                  fullWidth={true}
                  name={"url"}
                  defaultValue={null}
              />
              <TextInput
                  source="urlKey"
                  label="Url Key"
                  fullWidth={true}
                  name={"urlKey"}
                  defaultValue={""}
              />
              <RichTextInput
                  source="description"
                  label="Descripción"
                  fullWidth={true}
                  name={"description"}
                  required
                  multiline
              />
              <RichTextInput
                  source="summary"
                  label="Resumen"
                  fullWidth={true}
                  name={"summary"}
                  required
                  multiline
              />
            </CardContent>
          </Card>

          <br/>

          <Card variant="outlined" sx={{ minWidth: 1600 }}>
            <CardHeader
                title="Imagenes"
            />
            <CardContent>
              <Labeled label="Imagen de portada" fullWidth>
                <>
                  <TextInput source="icon.title" label="Titulo" fullWidth defaultValue={null}/>
                  <TextInput source="icon.url" label="Url" fullWidth defaultValue={null}/>
                </>
              </Labeled>

              <br/>
              <Labeled label="Imagen de portada" fullWidth>
                <>
                  <TextInput source="coverImage.title" label="Titulo" fullWidth defaultValue={null}/>
                  <TextInput source="coverImage.url" label="Url" fullWidth defaultValue={null}/>
                </>
              </Labeled>

              <br/>
              <ArrayInput source="image" label="Imagenes" fullWidth defaultValue={[]}>
                <SimpleFormIterator fullWidth>
                  <TextInput source="title" label="Titulo" fullWidth/>
                  <TextInput source="url" label="URL" fullWidth/>
                </SimpleFormIterator>
              </ArrayInput>
            </CardContent>
          </Card>

          <br/>

          <Card variant="outlined" sx={{ minWidth: 1600 }}>
            <CardHeader
                title="Informacion de contacto"
            />
            <CardContent>
              <TextInput
                  source="phone"
                  label="Telefono de contacto"
                  fullWidth={true}
                  name={"phone"}
                  defaultValue={null}
              />
              <TextInput
                  source="email"
                  label="Email de contacto"
                  fullWidth={true}
                  name={"email"}
                  defaultValue={null}
              />
            </CardContent>
          </Card>

          <br/>

          <Card variant="outlined" sx={{ minWidth: 1600 }}>
            <CardHeader
                title="Referencias"
            />
            <CardContent>

              <ReferenceInput source="district" reference="district">
                <SelectInput
                    label="Comuna"
                    optionText="name"
                    fullWidth
                    defaultValue={null}
                ></SelectInput>
              </ReferenceInput>

              <ReferenceInput source="activitiesTypes" reference="activitiesTypes">
                <SelectInput
                    label="Tipo"
                    optionText="name"
                    fullWidth
                    defaultValue={null}
                ></SelectInput>
              </ReferenceInput>

              <ReferenceArrayInput source="activitiesCategories" reference="activitiesCategories">
                <SelectArrayInput
                    optionText="name"
                    label="Categoria"
                    fullWidth
                    defaultValue={null}
                />
              </ReferenceArrayInput>

              <br />

              <ReferenceInput source="interestPlaces" reference="interestPlaces">
                <SelectInput
                    label="Lugar de Interes"
                    optionText="name"
                    fullWidth
                    defaultValue={null}
                    onChange={handleInterestPlaces}
                ></SelectInput>
              </ReferenceInput>
            </CardContent>
          </Card>

          <br />
          <TextInput
              source="address"
              label="Direccion"
              fullWidth={true}
              name={"address"}
              disabled={!isAddressEnabled}
              defaultValue={null}
          />

          <ArrayInput source="additionalInformation" label="Informacion adicional" fullWidth defaultValue={[]}>
            <SimpleFormIterator fullWidth>
              <TextInput source="title" label="Titulo" fullWidth/>
              <TextInput source="description" label="Descripcion" fullWidth/>
              <TextInput source="url" label="URL" fullWidth/>
              <TextInput source="icon" label="Icono" fullWidth/>
            </SimpleFormIterator>
          </ArrayInput>

          <br/>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={2}>
              {
                <Labeled label="Fecha desde">
                  <TextInput
                      source="dateFrom"
                      label=""
                      type="date"
                      format={dateFormatter}
                      parse={dateParser}
                      color="secondary"
                      variant="outlined"
                      required
                  />
                </Labeled>
              }
            </Grid>
            <Grid item xs={6} sm={2}>
              {
                <Labeled label="Fecha hasta">
                  <TextInput
                      source="dateTo"
                      label=""
                      type="date"
                      format={dateFormatter}
                      parse={dateParser}
                      color="secondary"
                      variant="outlined"
                      required
                  />
                </Labeled>
              }
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={2}>
              {
                <Labeled label="Hora desde">
                  <TextInput
                      source="time"
                      label=" "
                      type="time"
                      className="time_event"
                      color="secondary"
                      variant="outlined"
                      required
                  />
                </Labeled>
              }
            </Grid>
            <Grid item xs={6} sm={2}>
              {
                <Labeled label="Hora hasta">
                  <TextInput
                      source="endTime"
                      label=" "
                      type="time"
                      className="time_event"
                      color="secondary"
                      variant="outlined"
                      required
                  />
                </Labeled>
              }
            </Grid>
          </Grid>
          <RichTextInput
              source="newTime"
              label="Hora"
              fullWidth={true}
              name={"newTime"}
              defaultValue={null}
              multiline
          />
        </SimpleForm>
      </Create>
  );
}
export default CreateActivities;
