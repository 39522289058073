import "./App.scss";

import React from "react";
import { Admin, Resource } from "react-admin";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import AuthProvider from "./auth";
import uploadCapatibilies from "./helpers/uploadCapabilities";
import I18nProvider from "./i18n";
import MyLayout from "./MyLayout";
import Provider from "./provider";
import { fetchJson as httpClient } from "./provider/httpClient";
import Appointments from "./resources/appointments";
import Banners from "./resources/banners";
import Commerce from "./resources/commerce";
import Dashboard from "./resources/dashboard";
import Discount from "./resources/discount";
import Events from "./resources/events";
import Formalities from "./resources/formalities";
import HelpCommunity from "./resources/helpCommunity";
import History from "./resources/history";
import Projects from "./resources/projects";
import LoginPage from "./resources/login";
import Maps from "./resources/maps";
import News from "./resources/news";
import Notification from "./resources/notifications";
import Organization from "./resources/organization";
import OrganizationAccess from "./resources/organization-access";
import CdirDepartment from './resources/cdirDepartment';
import CdirProjectsCategories from './resources/cdirProjectsCategories';
import Product from "./resources/product";
import Equipo from "./resources/team";
import Users from "./resources/users";
import ExternalLink from "./resources/externalLink";
import Theme from "./theme";
import UrlProvider from "./urlprovider";

import Senders from './resources/sender';

// CATEGORIES
import ProductCategories from "./resources/productCategories";
import DiscountCategories from "./resources/discountCategories";
import OrganizationCategories from "./resources/organizationCategories";

// CITY TOUR
import Activities from "./resources/culturalHeritage/activities";
import ActivitiesCategories from "./resources/culturalHeritage/activitiesCategories";
import ActivitiesTypes from "./resources/culturalHeritage/activitiesTypes";
import InterestPlaces from "./resources/culturalHeritage/interestPlaces";
import District from "./resources/culturalHeritage/district";

// FIREBASE
import { firebaseConfig } from "./firebaseConfig";
import { FirebaseDataProvider } from "react-admin-firebase";

// PROVIDERS
const firebaseDataProvider = FirebaseDataProvider(firebaseConfig);
const mongodbProvider = uploadCapatibilies(Provider(UrlProvider, httpClient));
const convertToCamelCase = (str) => {
  let words = str.toLowerCase().split("_");
  for (let i = 1; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }
  return words.join("");
};

const mergeCase = (type, resource, params) => {
  try {
    const method = convertToCamelCase(type);
    return Promise.resolve(firebaseDataProvider[method](resource, params));
  } catch (e) {
    throw e;
  }
};

const dataProvider = (type, resource, params) => {
  let result;
  switch (resource) {
    case "activitiesTypes":
    case "interestPlaces":
    case "district":
    case "activitiesCategories":
    case "activities":
      result = mergeCase(type, resource, params);
      return result;
    default:
      result = mongodbProvider(type, resource, params);
      return result;
  }
};

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Admin
        authProvider={AuthProvider}
        theme={Theme}
        dataProvider={dataProvider}
        i18nProvider={I18nProvider}
        dashboard={Dashboard}
        layout={MyLayout}
        loginPage={LoginPage}
      >
        {(permissions) => {
          return [
            <Resource
              name="news"
              list={
                permissions["news"] && permissions["news"].list
                  ? News.list
                  : null
              }
              create={
                permissions["news"] && permissions["news"].create
                  ? News.create
                  : null
              }
              edit={
                permissions["news"] && permissions["news"].edit
                  ? News.edit
                  : null
              }
              icon={News.icon}
              options={News.options}
            />,

            <Resource name="profile" />,

            <Resource
              name="city/events"
              list={
                permissions["city/events"] && permissions["city/events"].list
                  ? Events.list
                  : null
              }
              create={
                permissions["city/events"] && permissions["city/events"].create
                  ? Events.create
                  : null
              }
              edit={
                permissions["city/events"] && permissions["city/events"].edit
                  ? Events.edit
                  : null
              }
              icon={Events.icon}
              options={Events.options}
            />,
            <Resource
              name="users"
              {...Users}
              list={
                permissions["users"] && permissions["users"].list
                  ? Users.list
                  : null
              }
              create={
                permissions["users"] && permissions["users"].create
                  ? Users.create
                  : null
              }
              edit={
                permissions["users"] && permissions["users"].edit
                  ? Users.edit
                  : null
              }
              icon={Users.icon}
              options={Users.options}
            />,

            <Resource
              name="city/maps"
              {...Maps}
              list={
                permissions["city/maps"] && permissions["city/maps"].list
                  ? Maps.list
                  : null
              }
              create={
                permissions["city/maps"] && permissions["city/maps"].create
                  ? Maps.create
                  : null
              }
              edit={
                permissions["city/maps"] && permissions["city/maps"].edit
                  ? Maps.edit
                  : null
              }
              icon={Maps.icon}
              options={Maps.options}
            />,

            <Resource
              name="city/appointment"
              list={
                permissions["city/appointment"] &&
                permissions["city/appointment"].list
                  ? Appointments.list
                  : null
              }
              create={
                permissions["city/appointment"] &&
                permissions["city/appointment"].create
                  ? Appointments.create
                  : null
              }
              edit={
                permissions["city/appointment"] &&
                permissions["city/appointment"].edit
                  ? Appointments.edit
                  : null
              }
              icon={Appointments.icon}
              options={Appointments.options}
            />,
            <Resource
              name="city/banners"
              list={
                permissions["city/bannersApp"] &&
                permissions["city/bannersApp"].list
                  ? Banners.list
                  : null
              }
              create={
                permissions["city/bannersApp"] &&
                permissions["city/bannersApp"].create
                  ? Banners.create
                  : null
              }
              edit={
                permissions["city/bannersApp"] &&
                permissions["city/bannersApp"].edit
                  ? Banners.edit
                  : null
              }
              icon={Banners.icon}
              options={Banners.options}
            />,
            <Resource
              name="city/formalities"
              list={
                permissions["city/formalities"] &&
                permissions["city/formalities"].list
                  ? Formalities.list
                  : null
              }
              create={
                permissions["city/formalities"] &&
                permissions["city/formalities"].create
                  ? Formalities.create
                  : null
              }
              edit={
                permissions["city/formalities"] &&
                permissions["city/formalities"].edit
                  ? Formalities.edit
                  : null
              }
              icon={Formalities.icon}
              options={Formalities.options}
            />,
            <Resource
              name="city/commerce"
              list={
                permissions["city/commerce"] &&
                permissions["city/commerce"].list
                  ? Commerce.list
                  : null
              }
              create={
                permissions["city/commerce"] &&
                permissions["city/commerce"].create
                  ? Commerce.create
                  : null
              }
              edit={
                permissions["city/commerce"] &&
                permissions["city/commerce"].edit
                  ? Commerce.edit
                  : null
              }
              icon={Commerce.icon}
              options={Commerce.options}
            />,

            <Resource
              name="city/helpCommunity"
              list={
                permissions["city/helpCommunity"] &&
                permissions["city/helpCommunity"].list
                  ? HelpCommunity.list
                  : null
              }
              create={
                permissions["city/helpCommunity"] &&
                permissions["city/helpCommunity"].create
                  ? HelpCommunity.create
                  : null
              }
              edit={
                permissions["city/helpCommunity"] &&
                permissions["city/helpCommunity"].edit
                  ? HelpCommunity.edit
                  : null
              }
              icon={HelpCommunity.icon}
              options={HelpCommunity.options}
            />,
            <Resource
              name="city/notifications/campaigns"
              list={
                permissions["city/notifications/campaigns"] &&
                permissions["city/notifications/campaigns"].list
                  ? Notification.list
                  : null
              }
              create={
                permissions["city/notifications/campaigns"] &&
                permissions["city/notifications/campaigns"].create
                  ? Notification.create
                  : null
              }
              edit={
                permissions["city/notifications/campaigns"] &&
                permissions["city/notifications/campaigns"].edit
                  ? Notification.edit
                  : null
              }
              icon={Notification.icon}
              options={Notification.options}
            />,
            <Resource
              name="cdir/department"
              list={
                permissions["cdir/department"] &&
                permissions["cdir/department"].list
                  ? CdirDepartment.list
                  : null
              }
              create={
                permissions["cdir/department"] &&
                permissions["cdir/department"].create
                  ? CdirDepartment.create
                  : null
              }
              edit={
                permissions["cdir/department"] &&
                permissions["cdir/department"].edit
                  ? CdirDepartment.edit
                  : null
              }
              icon={CdirDepartment.icon}
              options={CdirDepartment.options}
            />,
            <Resource
              name="cdir/projectsCategories"
              list={
                permissions["cdir/projectsCategories"] &&
                permissions["cdir/projectsCategories"].list
                  ? CdirProjectsCategories.list
                  : null
              }
              create={
                permissions["cdir/projectsCategories"] &&
                permissions["cdir/projectsCategories"].create
                  ? CdirProjectsCategories.create
                  : null
              }
              edit={
                permissions["cdir/projectsCategories"] &&
                permissions["cdir/projectsCategories"].edit
                  ? CdirProjectsCategories.edit
                  : null
              }
              icon={CdirProjectsCategories.icon}
              options={CdirProjectsCategories.options}
            />,

            <Resource name="profile" />,
            <Resource
              name="cdir/news"
              list={
                permissions["cdir/news"] && permissions["cdir/news"].list
                  ? News.list
                  : null
              }
              create={
                permissions["cdir/news"] && permissions["cdir/news"].create
                  ? News.create
                  : null
              }
              edit={
                permissions["cdir/news"] && permissions["cdir/news"].edit
                  ? News.edit
                  : null
              }
              icon={News.icon}
              options={{ label: "Noticias Cdir" }}
            />,
            <Resource
              name="cdir/projects"
              list={
                permissions["cdir/projects"] && permissions["cdir/projects"].list
                  ? Projects.list
                  : null
              }
              create={
                permissions["cdir/projects"] && permissions["cdir/projects"].create
                  ? Projects.create
                  : null
              }
              edit={
                permissions["cdir/projects"] && permissions["cdir/projects"].edit
                  ? Projects.edit
                  : null
              }
              icon={Projects.icon}
              options={{ label: "Proyectos Cdir" }}
            />,
            <Resource
              name="cdir/team"
              list={
                permissions["cdir/history"] && permissions["cdir/history"].list
                  ? Equipo.list
                  : null
              }
              create={
                permissions["cdir/history"] &&
                permissions["cdir/history"].create
                  ? Equipo.create
                  : null
              }
              edit={
                permissions["cdir/history"] && permissions["cdir/history"].edit
                  ? Equipo.edit
                  : null
              }
              icon={Equipo.icon}
              options={{ label: "Team" }}
            />,
            <Resource
              name="cdir/history"
              list={
                permissions["cdir/history"] && permissions["cdir/history"].list
                  ? History.list
                  : null
              }
              create={
                permissions["cdir/history"] &&
                permissions["cdir/history"].create
                  ? History.create
                  : null
              }
              edit={
                permissions["cdir/history"] && permissions["cdir/history"].edit
                  ? History.edit
                  : null
              }
              icon={History.icon}
              options={{ label: "Historia Cdir" }}
            />,
            <Resource
              name="organization"
              list={
                permissions["organization"] && permissions["organization"].list
                  ? Organization.list
                  : null
              }
              create={
                permissions["organization"] &&
                permissions["organization"].create
                  ? Organization.create
                  : null
              }
              edit={
                permissions["organization"] && permissions["organization"].edit
                  ? Organization.edit
                  : null
              }
              icon={Organization.icon}
              options={{ label: "Organizaciones" }}
            />,
            <Resource
              name="organization-access"
              list={
                permissions["organization-access"] &&
                permissions["organization-access"].list
                  ? OrganizationAccess.list
                  : null
              }
              create={
                permissions["organization-access"] &&
                permissions["organization-access"].create
                  ? OrganizationAccess.create
                  : null
              }
              edit={
                permissions["organization-access"] &&
                permissions["organization-access"].edit
                  ? OrganizationAccess.edit
                  : null
              }
              icon={OrganizationAccess.icon}
              options={{ label: "Organizaciones Accesos" }}
            />,
            <Resource
              name="product"
              list={
                permissions["product"] && permissions["product"].list
                  ? Product.list
                  : null
              }
              create={
                permissions["product"] && permissions["product"].create
                  ? Product.create
                  : null
              }
              edit={
                permissions["product"] && permissions["product"].edit
                  ? Product.edit
                  : null
              }
              icon={Product.icon}
              options={{ label: "Productos" }}
            />,
            <Resource
              name="discount"
              list={
                permissions["discount"] && permissions["discount"].list
                  ? Discount.list
                  : null
              }
              create={
                permissions["discount"] && permissions["discount"].create
                  ? Discount.create
                  : null
              }
              edit={
                permissions["discount"] && permissions["discount"].edit
                  ? Discount.edit
                  : null
              }
              icon={Discount.icon}
              options={{ label: "Descuentos" }}
            />,
            <Resource
              name="productCategories"
              list={
                permissions["productCategories"] &&
                permissions["productCategories"].list
                  ? ProductCategories.list
                  : null
              }
              create={
                permissions["productCategories"] &&
                permissions["productCategories"].create
                  ? ProductCategories.create
                  : null
              }
              edit={
                permissions["productCategories"] &&
                permissions["productCategories"].edit
                  ? ProductCategories.edit
                  : null
              }
              icon={ProductCategories.icon}
              options={ProductCategories.options}
            />,
            <Resource
              name="discountCategories"
              list={
                permissions["discountCategories"] &&
                permissions["discountCategories"].list
                  ? DiscountCategories.list
                  : null
              }
              create={
                permissions["discountCategories"] &&
                permissions["discountCategories"].create
                  ? DiscountCategories.create
                  : null
              }
              edit={
                permissions["discountCategories"] &&
                permissions["discountCategories"].edit
                  ? DiscountCategories.edit
                  : null
              }
              icon={DiscountCategories.icon}
              options={DiscountCategories.options}
            />,
            <Resource
              name="organizationCategories"
              list={
                permissions["organizationCategories"] &&
                permissions["organizationCategories"].list
                  ? OrganizationCategories.list
                  : null
              }
              create={
                permissions["organizationCategories"] &&
                permissions["organizationCategories"].create
                  ? OrganizationCategories.create
                  : null
              }
              edit={
                permissions["organizationCategories"] &&
                permissions["organizationCategories"].edit
                  ? OrganizationCategories.edit
                  : null
              }
              icon={OrganizationCategories.icon}
              options={OrganizationCategories.options}
            />,
            <Resource
              name="activities"
              list={
                permissions["activities"] && permissions["activities"].list
                  ? Activities.list
                  : null
              }
              create={
                permissions["activities"] && permissions["activities"].create
                  ? Activities.create
                  : null
              }
              edit={
                permissions["activities"] && permissions["activities"].edit
                  ? Activities.edit
                  : null
              }
              icon={Activities.icon}
              options={Activities.options}
            />,
            <Resource
              name="activitiesCategories"
              list={
                permissions["activitiesCategories"] &&
                permissions["activitiesCategories"].list
                  ? ActivitiesCategories.list
                  : null
              }
              create={
                permissions["activitiesCategories"] &&
                permissions["activitiesCategories"].create
                  ? ActivitiesCategories.create
                  : null
              }
              edit={
                permissions["activitiesCategories"] &&
                permissions["activitiesCategories"].edit
                  ? ActivitiesCategories.edit
                  : null
              }
              icon={ActivitiesCategories.icon}
              options={ActivitiesCategories.options}
            />,
            <Resource
              name="activitiesTypes"
              list={
                permissions["activitiesTypes"] &&
                permissions["activitiesTypes"].list
                  ? ActivitiesTypes.list
                  : null
              }
              create={
                permissions["activitiesTypes"] &&
                permissions["activitiesTypes"].create
                  ? ActivitiesTypes.create
                  : null
              }
              edit={
                permissions["activitiesTypes"] &&
                permissions["activitiesTypes"].edit
                  ? ActivitiesTypes.edit
                  : null
              }
              icon={ActivitiesTypes.icon}
              options={ActivitiesTypes.options}
            />,
            <Resource
              name="interestPlaces"
              list={
                permissions["interestPlaces"] &&
                permissions["interestPlaces"].list
                  ? InterestPlaces.list
                  : null
              }
              create={
                permissions["interestPlaces"] &&
                permissions["interestPlaces"].create
                  ? InterestPlaces.create
                  : null
              }
              edit={
                permissions["interestPlaces"] &&
                permissions["interestPlaces"].edit
                  ? InterestPlaces.edit
                  : null
              }
              icon={InterestPlaces.icon}
              options={InterestPlaces.options}
            />,
            <Resource
              name="district"
              list={
                permissions["district"] && permissions["district"].list
                  ? District.list
                  : null
              }
              create={
                permissions["district"] && permissions["district"].create
                  ? District.create
                  : null
              }
              edit={
                permissions["district"] && permissions["district"].edit
                  ? District.edit
                  : null
              }
              icon={District.icon}
              options={District.options}
            />,
            <Resource
              name="externalLink"
              list={
                permissions["externalLink"] && permissions["externalLink"].list
                  ? ExternalLink.list
                  : null
              }
              create={
                permissions["externalLink"] && permissions["externalLink"].create
                  ? ExternalLink.create
                  : null
              }
              edit={
                permissions["externalLink"] && permissions["externalLink"].edit
                  ? ExternalLink.edit
                  : null
              }
              icon={ExternalLink.icon}
              options={ExternalLink.options}
            />,
            <Resource
              name="sender"
              list={
                permissions["sender"] && permissions["sender"].list
                  ? Senders.list
                  : null
              }
              create={
                permissions["sender"] && permissions["sender"].create
                  ? Senders.create
                  : null
              }
              edit={
                permissions["sender"] && permissions["sender"].edit
                  ? Senders.edit
                  : null
              }
              icon={Senders.icon}
              options={Senders.options}
            />
          ];
        }}
      </Admin>
    </LocalizationProvider>
  );
};

export default App;
