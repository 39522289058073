import React from "react";

import {
  BooleanField,
  ChipField,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceArrayField,
  SearchInput,
  SingleFieldList,
  TextField,
} from "react-admin";
import { getST } from "provider/Storage";

const ProductFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
  </Filter>
);

const ProductList = (props) => {
  const role = getST("role");

  return (
    <List
      {...props}
      title="Listado de productos"
      filters={<ProductFilter></ProductFilter>}
    >
      <Datagrid rowClick="edit">
        {role !== "commerce" ? (
          <ReferenceField
            source="organization"
            reference="organization"
            label="Comercio"
          >
            <TextField source="name" />
          </ReferenceField>
        ) : null}

        <TextField source="title" label="Título" />
        <TextField source="description" label="descripción" />
        <ReferenceArrayField
          label="Categoria"
          reference="productCategories"
          source="category"
        >
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="price" label="Precio" />
        <BooleanField source="active" label="Activo" />

        <EditButton label="Editar" />
      </Datagrid>
    </List>
  );
};

export default ProductList;
