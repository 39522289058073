import React from "react";
import { MdPlace } from "react-icons/md";
const icon = () => {
  return (
    <div>
      <MdPlace size={24}></MdPlace>
    </div>
  );
};
export default icon;
