import React from "react";

import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const EditActivitiesCategories = (props) => (
  <Edit title="Editar Categorias de Actividades" {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nombre" fullWidth={true} name={"name"} required/>
      <TextInput
          source="description"
          label="Descripción"
          fullWidth={true}
          name={"description"}
          required
      />
      <ReferenceInput
          source="district"
          reference="district"
      >
        <SelectInput
            label="Comuna"
            optionText="name"
            fullWidth
            defaultValue={null}
        ></SelectInput>

      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
export default EditActivitiesCategories;
