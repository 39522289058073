import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
} from 'react-admin'

const ProjectList = props => (
  <List {...props} title='Listado de proyectos'>
    <Datagrid>
      <TextField source='title' label='Título' />
      <TextField source='description' label='Descripcion' />
      <DateField source='publicationDate' label='Fecha de publicación' />
      <EditButton label='Editar' />
    </Datagrid>
  </List>
)
export default ProjectList
