import React from 'react'
import {
  TextInput,
  Create,
  SimpleForm,
  ImageInput,
  ImageField,
} from 'react-admin'

const ExternalLinkCreate = props => (
  <Create title='Crear Enlace Externo' {...props}>
    <SimpleForm>
      <TextInput source='title' label='Titulo' fullWidth={true} />
      <TextInput source='url' label='URL' fullWidth={true} />
      <ImageInput source='image' label='Imagen' accept='image/*'>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Create>
)

export default ExternalLinkCreate