import React from "react"
import { FaHandsHelping } from "react-icons/fa"
const icon = () => {
  return (
    <div>
      <FaHandsHelping size={24}></FaHandsHelping>
    </div>
  )
}
export default icon
