import React from "react"
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  Filter,
  SearchInput,
} from "react-admin"

const MapFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
  </Filter>
)

const MapList = (props) => (
  <List
    {...props}
    title="Listado de Mapas"
    filters={<MapFilter></MapFilter>}
  >
    <Datagrid>
      <TextField source="name" label="Nombre del mapa" />
      <TextField source="description" label="Descripción" />
      <BooleanField source="active" label="Activo" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
)
export default MapList
