import React from "react"
import { ImCalendar } from "react-icons/im"
const icon = () => {
  return (
    <div>
      <ImCalendar size={24}></ImCalendar>
    </div>
  )
}
export default icon
