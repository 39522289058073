import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT
} from "react-admin";

import { getST, setST } from "../provider/Storage";
import url from "../urlprovider";

// FIREBASE
import { authApp } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    try {
      const { username, password } = params;
      let email = username;
      email = email.trim();
      email = email.toLowerCase();
      const request = new Request(url + "/auth/login", {
        method: "POST",
        body: JSON.stringify({ username, email, password }),
        headers: new Headers({ "Content-Type": "application/json" })
      });
      request.headers.set("x-origin", "backoffice");
      let response = await fetch(request);
      console.log("response", response);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      let jsonData = await response.json();
      const { profile, services, id, roles } = jsonData;
      console.log("jsonData", jsonData);
      let authUser = authApp.currentUser;
      console.log("authUser", authUser);
      if (!authUser) {
        try {
          authUser = await signInWithEmailAndPassword(
            authApp,
            username,
            password
          );
          console.log("result authUser", authUser);
        } catch (error) {
          console.log("error", error);
        }
      }

      let role;
      if (roles.find((rol) => rol === "admin") === "admin") {
        role = "admin";
      } else if (
        roles.find((rol) => rol === "admin-tvecino") === "admin-tvecino"
      ) {
        role = "admin-tvecino";
      } else if (
        roles.find((rol) => rol === "admin-municipal") === "admin-municipal"
      ) {
        role = "admin-municipal";
      } else if (roles.find((rol) => rol === "admin-cdir") === "admin-cdir") {
        role = "admin-cdir";
      } else if (roles.find((rol) => rol === "commerce") === "commerce") {
        role = "commerce";
      } else if (
        roles.find((rol) => rol === "admin-cultura") === "admin-cultura"
      ) {
        role = "admin-cultura";
      } else if (
        roles.find((rol) => rol === "comunicaciones-cdir") ===
        "comunicaciones-cdir"
      ) {
        role = "comunicaciones-cdir";
      } else {
        role = "user";
      }

      console.log("role", role);

      if (role === "user") {
        return Promise.reject("Usuario no autorizado");
      } else {
        setST("user", profile);
        setST("token", services?.authToken || "");
        setST("role", role);
        setST("expiresAt", services?.expiresAt || "");
        setST("profile", JSON.stringify(profile));
        setST("id", id);
      }

      return Promise.resolve();
    } catch (error) {
      // console.log("error login:", error);
      return Promise.reject();
    }
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("profile");

      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    //return Promise.resolve;
    try {
      let expiresAt = getST("expiresAt");
      let d = new Date(expiresAt._d);
      let n = d.getTime();
      let now = new Date();
      let nw = now.getTime();

      if (n < nw) {
        return Promise.reject({ redirectTo: "/login?sessionExpired=true" });
      }
    } catch (error) {}
    let token = getST("token");
    return token
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login?sessionExpired=true" });
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = getST("role");
    return Promise.resolve(permissions[role]);
  }
  return Promise.resolve();
};
const permissions = {
  admin: {
    profile: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    mails: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    organization: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "organization-access": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    citizen: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    users: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "city/departments": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    "city/events": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    "city/maps": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "city/appointment": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "city/bannersApp": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "city/formalities": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "city/commerce": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "city/helpCommunity": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "city/notifications/campaigns": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    kpi: {
      total: true,
      scans: true,
      offline: true,
      users: true
    },
    "userintegration-offline": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false,
      url
    },
    "cdir/news": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false,
      url
    },

    "cdir/history": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "cdir/team": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    product: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    discount: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    productCategories: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    discountCategories: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    organizationCategories: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    activities: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    activitiesCategories: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    activitiesTypes: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    interestPlaces: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    district: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    "cdir/projects": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    "cdir/department": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    "cdir/projectsCategories": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    externalLink: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      delete: true
    },
    sender: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      delete: true
    }
  },
  "admin-tvecino": {
    profile: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    citizen: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    kpi: {
      total: false,
      scans: false,
      offline: false,
      users: false
    }
  },
  "admin-cultura": {
    activities: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    activitiesCategories: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    activitiesTypes: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    interestPlaces: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    "city/events": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    }
  },
  "comunicaciones-cdir": {
    activities: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    activitiesCategories: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    activitiesTypes: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    interestPlaces: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    "city/events": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    "cdir/projects": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: true
    },
    "cdir/department": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: true
    },
    "cdir/projectsCategories": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: true
    },
    externalLink: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      delete: true
    },
    "cdir/team": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    "cdir/news": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false,
      url
    }
  },
  "admin-cdir": {
    "city/events": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },

    product: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    discount: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    "cdir/news": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false,
      url
    },

    "cdir/history": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    cdirteam: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: true,
      delete: true
    },
    kpi: {
      total: true,
      scans: true,
      offline: true,
      users: true
    }
  },

  "admin-municipal": {
    "city/events": {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    }
  },
  organization: {
    enabled: true,
    list: true,
    create: true,
    edit: true,
    show: true,
    delete: true
  },
  "organization-access": {
    enabled: true,
    list: true,
    create: true,
    edit: true,
    show: true,
    delete: true
  },

  commerce: {
    product: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    },
    discount: {
      enabled: true,
      list: true,
      create: true,
      edit: true,
      show: false,
      delete: false
    }
  }
};
