import * as React from 'react';
import { useState } from 'react';

// @ts-ignore
import { useController } from 'react-hook-form';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 70,
    label: '70%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 90,
    label: '90%',
  },
  {
    value: 100,
    label: '100%',
  },

];

function valuetext(value) {
  return `${value}%`;
}

export default function SliderPercentage({ name, label, ...props }) {



  const { field } = useController({ name: name })


  const [value, setValue] = useState(field.value)
  const onChangeValue = (e) => {
    field.onChange(e.target.value ?? 0) // data send back to hook form
    setValue(e.target.value ?? 0) // UI state
  }

  return (
    <Box mt={4} ml={2} sx={{ width: 600 }}>
      <Slider
        {...field}
        aria-label="Always visible"
        defaultValue={0}
        onChange={onChangeValue}

        getAriaValueText={valuetext}
        step={1}
        marks={marks}
        valueLabelDisplay="on"
        value={value}


      />
    </Box>
  );
}

