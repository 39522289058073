import React from "react"
import { FcFlowChart } from "react-icons/fc"
const icon = () => {
  return (
    <div>
      <FcFlowChart size={24}></FcFlowChart>
    </div>
  )
}
export default icon
