import React from "react";
import { BsFillPinMapFill } from "react-icons/bs";
const icon = () => {
  return (
    <div>
      <BsFillPinMapFill size={24}></BsFillPinMapFill>
    </div>
  );
};
export default icon;
