import React from "react";

import {
  Edit,
  ImageField,
  ImageInput,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

const validateTitle = [required(), minLength(2), maxLength(200)];

const EditProduct = (props) => (
  <Edit {...props} title="Editar información de categorias descuentos">
    <SimpleForm>
      <TextInput
        source="title"
        label="Nombre de la categoria"
        fullWidth={true}
        validate={validateTitle}
      />
      <TextInput source="description" label="Descripción" fullWidth={true} />
      <ImageInput source="image" label="Imagen" accept="image/*" name={"image"}>
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export default EditProduct;
