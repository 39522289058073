import React from 'react';

import {
  BooleanInput,
  Create,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const validateTitle = [required(), minLength(2), maxLength(200)]

const FormalitiesCreate = (props) => (
  <Create title="Crear trámites." {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Nombre del trámite"
        fullWidth={true}
        validate={validateTitle}
      />
      <TextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        required
      />
      <TextInput
        source="url"
        label="Url"
        fullWidth={true}
        type="url"
        
      />
      <TextInput
        source="icon"
        label="Icono"
        fullWidth={true}
        type="url"
        
      />
      <BooleanInput source="active" label="Activo" />
      <BooleanInput source="requireAuth" label="Requiere Auth" />
    </SimpleForm>
  </Create>
)
export default FormalitiesCreate
