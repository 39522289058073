import "./style.scss";
import React from "react";
import AmountInput from "components/fields/amountInput";
import SliderPercentage from "components/fields/percentage";
import { dateFormatter, dateParser } from "helpers/date";
import {
  BooleanInput,
  CheckboxGroupInput,
  Create,
  FormDataConsumer,
  ImageField,
  ImageInput,
  Labeled,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { getST } from "provider/Storage";

const DiscountCreate = (props) => {
  const role = getST("role");
  console.log(role);
  return (
    <Create title="Crear Descuento" {...props}>
      <SimpleForm>
        {role === "admin" ? (
          <Card variant="outlined" sx={{ minWidth: "100%" }}>
            <CardHeader title="Información del Comercio" />
            <CardContent>
              <ReferenceInput
                source="organization"
                reference="organization"
                filter={{ type: "commerce" }}
                validate={required()}
                allowEmpty={true}
              >
                <SelectInput
                  label="Nombre"
                  optionText="name"
                  fullWidth
                  validate={required("Seleccione un comercio")}
                ></SelectInput>
              </ReferenceInput>
              <br></br>

              <Labeled label="Marcar este descuento como destacado" fullWidth>
                <BooleanInput source="featured" label="" />
              </Labeled>
            </CardContent>
          </Card>
        ) : null}
        <br></br>

        <Card variant="outlined" sx={{ minWidth: "100%" }}>
          <CardHeader title="Información del beneficio/descuento" />
          <CardContent>
            <Labeled label="Título del beneficio/descuento" fullWidth>
              <TextInput
                source="title"
                label=""
                fullWidth
                required={true}
                name={"title"}
              />
            </Labeled>
            <Labeled label="Descripción del beneficio/descuento" fullWidth>
              <TextInput
                source="description"
                label=""
                fullWidth
                multiline
                name={"description"}
              />
            </Labeled>

            <Labeled label="Fecha de Inicio del descuento">
              <TextInput
                source="dateFrom"
                label=" "
                type="date"
                format={dateFormatter}
                parse={dateParser}
                color="secondary"
                variant="outlined"
                className="date-admin"
              />
            </Labeled>
            <br></br>
            <Labeled label="Fecha de termino del descuento">
              <TextInput
                source="dateTo"
                label=" "
                type="date"
                format={dateFormatter}
                parse={dateParser}
                color="secondary"
                variant="outlined"
                className="date-admin"
              />
            </Labeled>
            <br></br>

            <Labeled label="Tipo de Descuento">
              <SelectInput
                validate={required("Seleccione un tipo de descuento")}
                source="discountType"
                label=""
                choices={[
                  { id: "percent", name: "Porcentaje" },
                  { id: "amount", name: "Monto" },
                ]}
              />
            </Labeled>
            <br></br>

            <Labeled label="Categoria">
              <ReferenceArrayInput
                source="category"
                reference="discountCategories"
              >
                <SelectArrayInput optionText="title" />
              </ReferenceArrayInput>
            </Labeled>

            <br></br>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.discountType === "amount" && (
                  <Labeled label="Monto">
                    <AmountInput
                      name="amount"
                      source="amount"
                      defaultValue={0}
                      label="Ingrese el monto del descuento"
                      fullWidth
                    />
                  </Labeled>
                )
              }
            </FormDataConsumer>
            <br></br>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.discountType === "percent" && (
                  <Labeled label="Porcentaje de descuento">
                    <SliderPercentage
                      name="percent"
                      source="percent"
                      label="Porcentaje"
                    />
                  </Labeled>
                )
              }
            </FormDataConsumer>
            <br></br>

            <Labeled label="Baner del descuento" fullWidth>
              <ImageInput source="image" label=" " accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </Labeled>
          </CardContent>
        </Card>
        <br></br>
        <Card variant="outlined" sx={{ minWidth: "100%" }}>
          <CardHeader title="Productos asociados al beneficio/descuento " />
          <CardContent>
            <Labeled label="Productos asociados al beneficio/descuento">
              <ReferenceArrayInput
                source="products"
                reference="product"
                label="Seleccione los productos"
              >
                <SelectArrayInput optionText="title" />
              </ReferenceArrayInput>
            </Labeled>
          </CardContent>
        </Card>
        <br></br>

        <Card variant="outlined" sx={{ minWidth: "100%" }}>
          <CardHeader title="Restricciones del descuento" />
          <CardContent>
            <Labeled label="Limite maximo de uso del Beneficio/Descuento (Si no hay limites de uso deje el valor en 0)">
              <NumberInput
                source="restrictions.limit"
                label=""
                min={0}
                className="limit"
                defaultValue={0}
              />
            </Labeled>
            <br></br>
            <Labeled label="Máximo diario de uso del  Beneficio/Descuento (Si no existe un máximo de uso diario deje el valor en 0)">
              <NumberInput
                source="restrictions.maxUsePerDay"
                label=""
                className="limit"
                min={0}
                defaultValue={0}
              />
            </Labeled>
            <br></br>
            <Labeled label="Días de la semana cuando es válido el descuento">
              <CheckboxGroupInput
                label=" "
                source="restrictions.daysOfWeek"
                choices={[
                  { id: "Mon", name: "Lunes" },
                  { id: "Tue", name: "Martes" },
                  { id: "Wed", name: "Miércoles" },
                  { id: "Thu", name: "Jueves" },
                  { id: "Fri", name: "Viernes" },
                  { id: "Sat", name: "Sabado" },
                  { id: "Sun", name: "Domingo" },
                ]}
              />
            </Labeled>
            <br></br>
            <Labeled label="Activo">
              <BooleanInput source="active" label="Activo" />
            </Labeled>
          </CardContent>
        </Card>
      </SimpleForm>
    </Create>
  );
};
export default DiscountCreate;
