import React from 'react';

import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  SelectField,
  TextField,
  TextInput,
} from 'react-admin';

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre" source="profile.firstName" alwaysOn />
    <TextInput label="Apellido" source="profile.lastName" alwaysOn />
    <TextInput label="Rut" source="profile.identifier" alwaysOn />
  </Filter>
)

export const UserList = (props) => {
  return (
    <List {...props} filters={<UserFilter />}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="profile.firstName" label="Nombre" />
        <TextField source="profile.lastName" label="Apellido" />
        <TextField source="profile.identifier" label="Rut" />

        <SelectField
          source="profile.gender"
          choices={[
            { id: "hombre", name: "Masculino" },
            { id: "mujer", name: "Femenino" },
          ]}
          label="Género"
        />
        <DateField source="profile.birthdate" label="Fecha de nacimiento" />
        
        <EditButton label="Editar" />
      </Datagrid>
    </List>
  )
}

export default UserList
