import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

const ListInterestPlaces = (props) => (
  <List {...props} title="Listado de Lugares de Interes">
    <Datagrid>
      <TextField source="name" label="Nombre del lugar" />
      <TextField source="description" label="Descripción" />
      <TextField source="address.streetName" label="Direccion" />
      <TextField source="address.streetNumber" label="Numero" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
);
export default ListInterestPlaces;
