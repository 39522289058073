import React from "react"
import { List, Datagrid, TextField, EditButton, DateField } from "react-admin"
const NotificationList = (props) => (
  <List {...props} title="Campañas de Notificaciones">
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nombre de la campaña" />
      <TextField source="title" label="Titulo de la notificación" />
      <DateField source="date" label="Fecha de la campaña" />
      <TextField source="message" label="Mensaje" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
)
export default NotificationList
