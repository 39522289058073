import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyAdRw-XGKDXudUtcAVaReuJyiCK5XF3H-4",
  authDomain: "city-tour-84058.firebaseapp.com",
  projectId: "city-tour-84058",
  storageBucket: "city-tour-84058.appspot.com",
  messagingSenderId: "417225139801",
  appId: "1:417225139801:web:f250ff9173314cbce83348",
  measurementId: "G-33SXRWPNMJ"
};

const firebaseConfigRancagua = {
  apiKey: "AIzaSyA-wa376hgxAXhLs1udsv3K65wPLWDl9Fk",
  authDomain: "rancagua-admin.firebaseapp.com",
  databaseURL: "https://rancagua-admin.firebaseio.com",
  projectId: "rancagua-admin",
  storageBucket: "rancagua-admin.appspot.com",
  messagingSenderId: "405941478115",
  appId: "1:405941478115:web:1e6a4418b1be78b228dbd6",
  measurementId: "G-DZNGN3KZZ3"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAppRancagua = firebase.initializeApp(
  firebaseConfigRancagua,
  "rancagua"
);
export const storage = firebaseAppRancagua.storage();
export const storageRoot = storage.ref();
export const authApp = getAuth(firebaseApp);
