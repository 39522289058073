import React from 'react';

import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';

const EventFilter = (props) => (
  <Filter {...props}>
  <TextInput
          source="name"
          fullWidth={true}
          label="Título del evento"
          color="secondary"
          variant="outlined"
        />
        <TextInput
          source="subtitle"
          fullWidth={true}
          label="Subtítulo del evento"
          color="secondary"
          variant="outlined"
        />
  </Filter>
);
export const EventoList = (props) => (
  <List {...props} filters={<EventFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Titulo del evento" />
      <TextField source="subtitle" label="Subtitulo" />
      <DateField source="date" label="Fecha" locales="es-CL" options={{ timeZone: 'UTC' }}  />
      <EditButton label="Editar" />
      {/*       <DeleteButton label='Eliminar' />
       */}{" "}
    </Datagrid>
  </List>
);
export default EventoList;
