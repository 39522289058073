import React from 'react';
import {
  Datagrid,
  EditButton,
  List,
  TextField,
} from 'react-admin';

const ExternalLinkList = props => (
  <List title='Lista de Enlaces Externos' {...props}>
    <Datagrid>
      <TextField source='title' label='Titulo' />
      <TextField source='url' label='URL' />
      <EditButton />
    </Datagrid>
  </List>
);

export default ExternalLinkList;