import React, { useState } from 'react';

import {
  useLogin,
  useNotify,
} from 'react-admin';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { theme } from '../../theme';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.innovarancagua.cl/">
        Corporación de Desarrollo e Innovación de Rancagua
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}
// @ts-ignore
function MyLoginPage(props) {
  let [username, setUsername] = useState("")
  let [password, setPassword] = useState("")
  const login = useLogin();
  const notify = useNotify();

  const AdminColors = {
    100: "#ffffff",
    200: "#fcfcfc",
    300: "#fbaf44",
    500: "#2ea2dc",
    700: "#222222",
    900: "#000000",
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100vh",
    },
    title: {
      color:'#2071b7'
    },

    image: {
      background:
        "url(/assets/logo_innovacion.png) #ffffff no-repeat center center ",
      backgroundSize: "80%",
    },

    paper: {
      // @ts-ignore
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      // @ts-ignore
      margin: theme.spacing(1),
      backgroundColor: " #ffffff",
      color:"#ffffff"
    },
    icon:{
      color: " #603085",

    },
    form: {
      width: "100%", // Fix IE 11 issue.
      // @ts-ignore
      marginTop: theme.spacing(1),
    },
    submit: {
      // @ts-ignore
      margin: theme.spacing(3, 0, 2),
      backgroundColor: AdminColors["500"],
      color: AdminColors["100"],
      "&:hover": {
        backgroundColor: AdminColors["300"],
      },
    },
  }))
  const submit = (e) => {
    e.preventDefault()
    const credentials = { username: username, password: password }
    login(credentials).catch((e) =>{
    if(e === undefined){
      notify("Usuario o contraseña incorrectos",{ type: 'warning' })
    }
    notify(e,{ type: 'warning' })
  }
);
  }

  const classes = useStyles(theme)
  const handleChangeUsuario = (event) => {
    setUsername(event.target.value)
  }
  const handleChangePassword = (event) => {
    setPassword(event.target.value)
  }
  return (
    <Grid container component="main" className={classes.root}>
      {/*      <div>
        <AlertLogin opened={open} message={message}></AlertLogin>
      </div> */}
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
             <LockOutlinedIcon  className={classes.icon}/>
         </Avatar>
          <Typography component="h1" variant="h5" className={classes.title}>
            SITIO DE ADMINISTRACIÓN APP RANCAGUA
          </Typography>
          <form className={classes.form} noValidate onSubmit={submit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="Usuario"
              label="Usuario"
              name="Usuario"
              autoComplete="Usuario"
              autoFocus
              onChange={handleChangeUsuario.bind(this)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="Contraseña"
              onChange={handleChangePassword.bind(this)}
            />
     {/*        <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordar Contraseña"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Enviar
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

export default MyLoginPage
