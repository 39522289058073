import React from 'react'
import {
  TextField,
  DateField,
  Show,
  SimpleShowLayout
} from 'react-admin'
import ImageViewerField from 'helpers/fields/ImageViewerField';

const NewsShow = props => (
  <Show title='Mostrando Noticia' {...props}>
    <SimpleShowLayout>
      <TextField source='title' label='Título' />
      <TextField source='summary' label='Resumen' />
      <TextField source='description' label='Descripción' />
      <ImageViewerField source='cover' label='Portada' />
      <ImageViewerField source='previewImage' label='Imagen de previsualizacion' />
      <ImageViewerField source='gallery' label='Galeria de Imagenes' />
      <DateField source='publicationDate' label='Fecha de publicación' />
      <TextField source='video' label='Enlace de youtube' fullWidth />
      <TextField source='urlKey' label='Url Key' />
      <TextField source='id' />
    </SimpleShowLayout>
  </Show>
)
export default NewsShow
