import React from "react"
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  Filter,
  SearchInput,
} from "react-admin"

const BannerFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
  </Filter>
)

const BannerList = (props) => (
  <List
    {...props}
    title="Listado de Banners"
    filters={<BannerFilter></BannerFilter>}
  >
    <Datagrid>
      <TextField source="title" label="Titulo del banner" />
      <TextField source="description" label="Descripción" />
      <BooleanField source="active" label="Activo" />
      <TextField source="priority" label="Prioridad"/>
      <BooleanField source="requireAuth" label="Requiere autenticación" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
)
export default BannerList
