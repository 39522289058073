import React from 'react';

import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

const TeamShow = props => (
  <Show title='Mostrando team' {...props}>
    <SimpleShowLayout>
    <TextField source="name" label="Nombre" />
      <NumberField source="level" label="Nivel" />
    </SimpleShowLayout>
  </Show>
)
export default TeamShow
