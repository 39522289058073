import React from 'react';

import {
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

const OrganizationShow = (props) => (
  <Show title="Mostrando Organización" {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Nombre" />

      <TextField source="description" label="descripción" />

      <TextField source="authRole" label="Role" />
    </SimpleShowLayout>
  </Show>
)
export default OrganizationShow
