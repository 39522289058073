import React from 'react';

import {
  BooleanInput,
  Edit,
  maxLength,
  minLength,
  regex,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const validateTitle = [required(), minLength(2), maxLength(100)];
const validateUrl = [required(), minLength(2), maxLength(100), regex(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_.~#?&=]*)$/
, 'Ingrese una ruta válida')];
const AppointmentEdit = (props) => (
  <Edit title="Editar servicio de reserva de hora." {...props}>
       <SimpleForm>
      <TextInput
        source="name"
        label="Nombre del servicio"
        fullWidth={true}
        validate={validateTitle}
      />
      <TextInput source="description" label="Descripción" fullWidth={true} required  />
      <BooleanInput source="active" label="Activo" />
      <TextInput source="url" label="Url" fullWidth={true}  type="url" required   validate={validateUrl}/>
    </SimpleForm>
  </Edit>
);
export default AppointmentEdit;
