import React from "react"
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  SearchInput,
} from "react-admin"

const ProjectsCdirFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
  </Filter>
)

const CdirProjectsList = (props) => (
  <List
    {...props}
    title="Listado de Proyectos Cdir"
    filters={<ProjectsCdirFilter></ProjectsCdirFilter>}
  >
    <Datagrid>
      <TextField source="title" label="Nombre del departamento" />
      <TextField source="description" label="Descripción" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
)

export default CdirProjectsList