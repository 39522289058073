// @ts-nocheck
import queryString from 'query-string';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {};
    try {
      switch (type) {
        case GET_LIST: {
          try {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            var paramsFilter = params.filter;
            if (resource === "users") {
              try{
                var q = paramsFilter.q;
                if (q !== null) {
                  delete paramsFilter.q;
                  paramsFilter.email = q;
                }
                var ids = paramsFilter.id;
                if (ids !== null && ids.length > 0) {
                  delete paramsFilter.id;
                  paramsFilter._id = ids;
                }
              }catch(e){
                console.log(e)
              }
          
            }
            const query = {
              sort: JSON.stringify({ [field]: order === "ASC" ? 1 : -1 }),
              pageSize: JSON.stringify(perPage),
              page: JSON.stringify(page),
              skip: (page - 1) * perPage,
              limit: perPage,
              query: JSON.stringify(paramsFilter)
            };
            url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
            break;
          } catch (e) {
            console.log("error list users",e);
          }
          break;
        }

        case GET_ONE:
          url = `${apiUrl}/${resource}/${params.id}`;
          break;
        case GET_MANY: {
          const query = {
            filter: JSON.stringify({ id: params.ids })
          };
          url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
          break;
        }
        case GET_MANY_REFERENCE: {
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          console.log("filter", params.filter);

          const query = {
            sort: JSON.stringify({ [field]: order === "ASC" ? 1 : -1 }),
            skip: (page - 1) * perPage,
            limit: perPage,
            query: JSON.stringify({
              ...params.filter,
              [params.target]: params.id
            })
          };
          url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
          break;
        }
        case UPDATE:
          url = `${apiUrl}/${resource}/${params.id}`;
          options.method = "PUT";
          options.data = JSON.stringify(params.data);
          break;

        case CREATE:
          try {
            url =
              params && params.id
                ? `${apiUrl}/${resource}/${params.id}`
                : `${apiUrl}/${resource}`;
            options.method = "POST";
            options.data = JSON.stringify(params.data);
          } catch (error) {
            console.log(error);
          }

          break;
        case DELETE:
          url = `${apiUrl}/${resource}/${params.id}`;
          options.method = "DELETE";
          break;
        default:
          throw new Error(`Unsupported fetch action type ${type}`);
      }
    } catch (e) {}
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { data } = response;
    switch (type) {
      case GET_LIST:
        try {
          let arrayData = data.rows ? data.rows : data;
          return {
            data: arrayData.map((item) => {
              item.id = item._id;
              delete item._id;
              return item;
            }),
            total: data.total ? data.total : arrayData.length
          };
        } catch (e) {
          return {
            data: [],
            total: 0
          };
        }
      case GET_MANY:
      case GET_MANY_REFERENCE:
        try {
          let arrayData = data.rows ? data.rows : data;

          return {
            data: arrayData.map((item) => {
              item.id = item._id;
              delete item._id;
              return item;
            }),
            total: data.total ? data.total : arrayData.length
          };
        } catch (e) {
          return {
            data: [],
            total: 0
          };
        }
      case CREATE:
        try {
          if (data && data._id) {
            data.id = data._id;
            delete data._id;
          }
          console.log("CREATE", data);

          return data;
        } catch (error) {
          console.log(error);
          return [];
        }

      case UPDATE:
        if (data && data._id) {
          data.id = data._id;
          delete data._id;
        }

        return data;
      case DELETE:
      case DELETE_MANY:
        return { data: params };
      default:
        if (data && data._id) {
          data.id = data._id;
          delete data._id;
        }
        return { data };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    try {
      if (type === UPDATE_MANY) {
        return Promise.all(
          params.ids.map((id) =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: "PUT",
              body: JSON.stringify(params.data)
            })
          )
        ).then((responses) => ({
          data: responses.map((response) => response.json)
        }));
      }
      // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
      if (type === DELETE_MANY) {
        return Promise.all(
          params.ids.map((id) =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: "DELETE"
            })
          )
        ).then((responses) => ({
          data: responses.map((response) => response.json)
        }));
      }

      const { url, options } = convertDataRequestToHTTP(type, resource, params);

      return httpClient(url, options).then((response) =>
        convertHTTPResponse(response, type, resource, params)
      );
    } catch (e) {}
  };
};
