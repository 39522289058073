import create from './create'
import edit from './edit'
import list from './list'
import icon from './icon'

export default {
  list: list,
  create: create,
  edit: edit,
  icon: icon,
  options: { label: 'Links Externos' }
}
