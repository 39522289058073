import React from "react";
import {
  TextInput,
  SimpleForm,
  Create,
  ImageInput,
  ImageField,
  SelectArrayInput,
  ReferenceInput,
  Labeled,
  BooleanInput
} from "react-admin";

import { RichTextInput } from "ra-input-rich-text";
import { dateFormatter, dateParser } from "helpers/date";

const validateForm = (values) => {
  const { previewImage, banner } = values;

  const errors = {};
  if (!previewImage) {
    errors.previewImage = "Debes seleccionar una imagen";
  } else if (!banner) {
    errors.banner = "Debes seleccionar una imagen";
  }
  return errors;
};

const ProjectCreate = (props) => (
  <Create title="Crear Proyectos" {...props}>
    <SimpleForm validate={validateForm}>
      <TextInput source="urlKey" label="Url Key" fullWidth={true} required />
      <TextInput source="title" label="Titulo" fullWidth={true} required />
      <RichTextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        name={"description"}
        required
        multiline
      />
      <TextInput
        source="summary"
        label="Resumen"
        fullWidth={true}
        name={"summary"}
        multiline
      />
      <Labeled label="Fecha de publicacion">
        <TextInput
          source="publicationDate"
          label=""
          type="date"
          format={dateFormatter}
          parse={dateParser}
          color="secondary"
          variant="outlined"
          required
        />
      </Labeled>

      <ImageInput
        source="previewImage"
        label="Imagen de previsualizacion"
        accept="image/*"
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImageInput source="banner" label="Banner" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImageInput
        source="gallery"
        label="Galeria de Imagenes"
        accept="image/*"
        multiple={true}
        defaultValue={[]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <TextInput
        source="video"
        label="Enlace de youtube"
        type="video"
        fullWidth
      />
      <BooleanInput
        source="customLanding"
        label="Landing personalizada"
        color="secondary"
        variant="outlined"
        defaultValue={false}
      />
      <BooleanInput
        source="active"
        label="Activo"
        color="secondary"
        variant="outlined"
        defaultValue={true}
      />
      <ReferenceInput source="externalUrl" reference="externalLink">
        <SelectArrayInput
          label="Enlaces Externos"
          optionText="title"
          fullWidth
          defaultValue={[]}
        ></SelectArrayInput>
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
export default ProjectCreate;
