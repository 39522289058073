// @ts-nocheck
import React from 'react';

import {
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  SearchInput,
  TextField,
} from 'react-admin';

const OrganizationFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
  </Filter>
)

const OrganizationList = (props) => {
  return (
    <List
      {...props}
      title="Usuarios por organización"
      filters={<OrganizationFilter></OrganizationFilter>}
    >
      <Datagrid rowClick="edit">
        <ReferenceField source="user" reference="users" label="Email">
          <FunctionField
            label="Email"
            render={(record) => `${record.emails[0].address}`}
          />
        </ReferenceField>

        <ReferenceField
          source="organization"
          reference="organization"
          label="Organización"
        >
          <TextField source="name" />
        </ReferenceField>

        <EditButton label="Editar" />
      </Datagrid>
    </List>
  )
}
export default OrganizationList
