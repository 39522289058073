import React from 'react'
import { TextField, BooleanField, Show, SimpleShowLayout, UrlField } from 'react-admin'

const HelpCommunityFilterShow = props => (
  <Show title='Mostrando información de ayuda a la comunidad' {...props}>
    <SimpleShowLayout> <TextField source='id' />
      <TextField source='name' label='Nombre de la ayuda' />
      <TextField source='description ' label='Descripción' />
      <BooleanField source='active' label='Activo' />
      <UrlField source='url ' label='Url' />    
    </SimpleShowLayout>
  </Show>
)
export default HelpCommunityFilterShow
