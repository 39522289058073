import React from "react";

import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";

const CreateDistrict = (props) => (
  <Create title="Crear Comuna" {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nombre" fullWidth={true} name={"name"} required />
      <TextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        name={"description"}
        required
      />
    </SimpleForm>
  </Create>
);
export default CreateDistrict;
