import React from 'react';

import InputSearchPlace from 'components/fields/inputSearchPlace';
import {
  dateFormatter,
  dateParser,
} from 'helpers/date';
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  ImageField,
  ImageInput,
  Labeled,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const EventoCreate = (props) => {
  const validateRequired = [required()];

  return (
    <Create title="Crear Eventos" {...props}>
      <SimpleForm>
        <Card variant="outlined" sx={{ minWidth: 1100 }}>
          <CardHeader
            title="Información general del evento"
          //subheader="September 14, 2016"
          />
          <CardContent>
            <TextInput
              source="name"
              fullWidth={true}
              label="Título del evento"
              color="secondary"
              variant="outlined"
              validate={validateRequired}

            />
            <TextInput
              source="subtitle"
              fullWidth={true}
              label="Subtítulo del evento"
              color="secondary"
              variant="outlined"
            />

            <TextInput
              source="description"
              label="Descripción o detalles del evento"
              fullWidth
              multiline
              color="secondary"
              variant="outlined"
              validate={validateRequired}

            />

            <Labeled label="Fecha del evento">
              <TextInput
                source="date"
                label=" "
                type="date"
                format={dateFormatter}
                parse={dateParser}
                color="secondary"
                variant="outlined"
                validate={validateRequired}


              />
            </Labeled>
            <br></br>
            <Labeled label="Hora de Inicio">
              <TextInput
                source="time"
                label=" "
                type="time"
                className="time_event"
                color="secondary"
                variant="outlined"
              />
            </Labeled>
            <br></br>
            <Labeled label="Hora de fin">
              <TextInput
                source="endTime"
                label=" "
                type="time"
                className="time_event"
                color="secondary"
                variant="outlined"
                defaultValue={''}
              />
            </Labeled>

            <TextInput
              source="ticketUrl"
              label="Link de entradas o tickets"
              type="url"
              fullWidth={true}
              color="secondary"
              variant="outlined"
            />
            <TextInput
              source="externalUrl"
              label="Url del evento"
              type="url"
              fullWidth={true}
              color="secondary"
              variant="outlined"
            />

            <ImageInput
              source="image"
              label="Imagen del Evento"
              accept="image/*"
              variant="outlined"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <br></br>

            <ArrayInput
              source="tags"
              fullWidth={true}
              label="Categorías del Evento"
            >
              <SimpleFormIterator>
                <TextInput
                  source="tag"
                  label="Tag"
                  color="secondary"
                  variant="outlined"
                />
              </SimpleFormIterator>
            </ArrayInput>
            <br></br>
          </CardContent>
        </Card>
        <br></br>

        <br></br>


        <Card variant="outlined" sx={{ minWidth: 1100 }}>
          <CardHeader
            title="Ubicación del evento"
          //subheader="September 14, 2016"
          />
          <CardContent>
            <TextInput
              source="place"
              label="Lugar del evento"
              fullWidth={true}
              color="secondary"
              variant="outlined"
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <InputSearchPlace name="address"
                  address={formData.address}
                  {...rest}
                ></InputSearchPlace>
              )}
            </FormDataConsumer>
            {/*
        <BooleanInput
          source="sendNotification"
          label="Enviar Notificacioón para este evento"
          color="secondary"
          variant="outlined"
        />

       <TextInput
          source="notificationMessage"
          fullWidth={true}
          label="Mensaje para la Notificación"
          color="secondary"
          variant="outlined"
        /> 
        <ArrayInput source="validators" fullWidth={true} label="Validadores">
          <SimpleFormIterator>
            <TextInput
              source="email"
              label="Email"
              type="email"
              color="secondary"
              variant="outlined"
            />
            <TextInput
              source="name"
              label="Nombre"
              color="secondary"
              variant="outlined"
            />
            <TextInput
              source="lastName"
              label="Apellido"
              color="secondary"
              variant="outlined"
            />
            <TextInput
              source="identifier"
              label="Rut"
              color="secondary"
              variant="outlined"
            />
          </SimpleFormIterator>
        </ArrayInput>
*/}
          </CardContent>
        </Card>
        <br></br>
        <br></br>

        <Card variant="outlined" sx={{ minWidth: 1100 }}>
          <CardHeader
            title="Información  Sanitaria del evento"
          //subheader="September 14, 2016"
          />
          <CardContent>
            <ArrayInput
              source="healthRules"
              fullWidth={true}
              label="Indicaciones Sanitarias"
            >
              <SimpleFormIterator>
                <TextInput
                  source="healthRule"
                  label="Indicación Sanitaria"
                  color="secondary"
                  variant="outlined"
                />
              </SimpleFormIterator>
            </ArrayInput>
          </CardContent>
        </Card>
        <br></br>
        <Card variant="outlined" sx={{ minWidth: 1100 }}>
          <CardHeader
            title="Publicación del evento y otros datos"
          //subheader="September 14, 2016"
          />
          <CardContent>
            <Labeled label="Departamento municipal asociado o responsable">
              <ReferenceInput
                source="departmentId"
                reference="city/departments"
                label="Departmento"
                perPage={100}
                sort={{ field: 'name', order: 'ASC' }}
                color="secondary"
                variant="outlined"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Labeled>

            <BooleanInput
              source="showInCalendar"
              label="Mostrar en el Calendario"
              color="secondary"
              variant="outlined"
            />
          </CardContent>
        </Card>
      </SimpleForm>
    </Create>
  )
}
export default EventoCreate
