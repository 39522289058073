import React from "react"
import { MdNotificationsActive } from "react-icons/md"
const icon = () => {
    return (
        <div>
            <MdNotificationsActive size={24}></MdNotificationsActive>
        </div>
    )
}
export default icon
