import React from "react"
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  Filter,
  SearchInput,
} from "react-admin"

const CommerceFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
  </Filter>
)

const CommerceList = (props) => (
  <List
    {...props}
    title="Listado de trámites"
    filters={<CommerceFilter></CommerceFilter>}
  >
    <Datagrid>
      <TextField source="name" label="Nombre del convenio con el comercio" />
      <TextField source="description" label="Descripción" />
      <BooleanField source="active" label="Activo" />
      <BooleanField source="requireAuth" label="Requiere Auth" />
      
      <EditButton label="Editar" />
    </Datagrid>
  </List>
)
export default CommerceList
