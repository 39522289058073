import React from "react"
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  SearchInput,
} from "react-admin"

const DepartmentFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
  </Filter>
)

const DepartmentList = (props) => (
  <List
    {...props}
    title="Listado de Departamentos"
    filters={<DepartmentFilter></DepartmentFilter>}
  >
    <Datagrid>
      <TextField source="name" label="Nombre del departamento" />
      <TextField source="description" label="Descripción" />
      <TextField source="level" label="Nivel"/>
      <EditButton label="Editar" />
    </Datagrid>
  </List>
)

export default DepartmentList