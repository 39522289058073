import { createTheme } from '@mui/material/styles';

const AdminColors = {
  100: "#ffffff",
  200: "#e4528d",
  300: '#0e9c6c',
  500: '#2071b7',
  600: '#2ea2dc',
  800: "#603085",

  700: "#222222",
  900: "#000000",


}
export const theme = createTheme({

  palette: {
    mode:'light',
    primary: {
      main: AdminColors['500'],
    },
    secondary: {
      main: AdminColors['300'],
    },

    text: {
      secondary: '#2071b7',
    },
  },
  typography: {
    fontFamily: 'LatoWebMedium',
    h6: {
      fontSize: '1rem',
    },
  },
  overrides: {

    MuiCard: {
      root: {
        marginLeft: '1%',
        marginRight: '1%',
        background: AdminColors['200'],
        border: `1px solid ${AdminColors['500']}`,
        marginBottom: '1%'
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: AdminColors['500'],
        '& .MuiListItemIcon-root': {
          color: '#656565',
        },
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: AdminColors['900'],
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: AdminColors['500'],
      },
    },
    MuiFormGroup: {
      root: {
        color: AdminColors['500'],
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.875rem',
      },
    },
    MuiDivider: {
      root: {
        margin: '.75rem 0',
      },
    },
    MuiFormLabel: {
      root: {
        color: AdminColors['900'],
        //   background: AdminColors['100'],
      },
    },
    MuiCheckbox: {
      root: {
        color: '#616161',
      },
    },
    MuiIconButton: {
      label: {},
    },
    MuiButton: {
      root: {
        //background: '#ddd',
        color: AdminColors['500'],
        border: '1px solid transparent',
        borderRadius: 500,
        '&:hover': {
          background: `${AdminColors['300']} !important`,
          border: `1px solid ${AdminColors['300']}`,
        },
      },
      containedPrimary: {
        backgroundColor: AdminColors['300'],
        background: AdminColors['500'],

        color: `${AdminColors['100']}!important`,

      },
      textPrimary: {
        backgroundColor: AdminColors['100'],
        border: `1px solid ${AdminColors['500']}`,

        '& span': {
          color: AdminColors['500'],

          //  backgroundColor: AdminColors['100'],
        },
        '&:hover': {
          border: `1px solid ${AdminColors['500']}`,

        },
      },
      textSecondary: {
        border: '1px solid #b3b3b3',
        background: '#fff',
        '&:hover': {
          border: '1px solid #fff !important',
          background: '#dedede !important',
        },
      },
      label: {
        color: AdminColors['900'],

        paddingRight: '1rem',
        paddingLeft: '0.7rem',
        //background: AdminColors['500']

      },
    },
    MuiDrawer: {
      root: {
        background: AdminColors['100'],
        paddingTop: '10px',
        boxShadow: '-14px -7px 20px black',
      },
    },
    MuiTableRow: {
      root: {
        padding: '10px 0',
        background: AdminColors['100'],
        transition: 'background-color .3s ease',
        borderRight: `1px solid ${AdminColors['200']}`,
        '&:hover': {
          background: `${AdminColors['600']}!important; `,
        },

      },


      head: {
        backgroundColor: AdminColors['500'],
        color: AdminColors['900'],
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${AdminColors['500']} `,
        padding: '10px !important',
        color: AdminColors['500'],

      },

      head: {
        borderBottom: '1px solid #282828',
        fontSize: '0.75rem',
        textTransform: 'uppercase',
        letterSpacing: 1.2,
      },
    },
    MuiAppBar: {
      positionFixed: {
        background: `${AdminColors['500']} !important`,
        boxShadow: '13px -12px 20px 0px #000',
      },
      colorSecondary: {
        color: AdminColors['100'],
        textTransform: "uppercase"
      },

    },


    // @ts-ignore
    RaLayout: {
      content: {
        padding: '0 !important',
      },
    },
    RaListToolbar: {
      toolbar: {
        padding: '0 .55rem !important',
      },
    },
    RaDatagridHeaderCell: {
      icon: {
        color: '#717171 !important',
      },
    },
    RaSearchInput: {
      input: {
        paddingLeft: '.9rem',
        border: 0,
      },
    },
    RaFilterButton: {
      root: {
        marginRight: '1rem',
        '& button': {
          color: AdminColors['100'],
          backgroundColor: AdminColors['100'],
          '& span': {
            color: AdminColors['100'],
          },
          '&:hover': {
            backgroundColor: AdminColors['200'],
          },
        },
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsPaper: {
        backgroundColor: '#fff',
      },
    },
    RaLink: {
      link: {
        color: AdminColors["500"],
      },
    },
    RaLogout: {
      icon: {
        color: '#f90000!important',
      },
    },
    RaMenuItemLink: {
      root: {
        color: AdminColors["900"],
        '& .MuiListItemIcon-root': {
          color: AdminColors["900"],
        },
      },
      active: {
        backgroundColor: AdminColors["500"],
        color: AdminColors["100"],
        '& .MuiListItemIcon-root': {
          color: AdminColors["100"],
        },
      },
    },
    RaSidebar: {
      drawerPaper: {
        '@media (min-width: 0px) and (max-width: 599.95px)': {
          backgroundColor: `${AdminColors['500']} !important`,
        },
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        backgroundColor: AdminColors['500'],
      },
    },
    RaPaginationActions: {
      button: {
        backgroundColor: 'inherit',
        minWidth: 48,
        margin: '0 4px',
        border: `1px solid ${AdminColors['500']} `,
        '@global': {
          '> .MuiButton-label': {
            padding: 0,
          },
        },
        '&:active': {
          color: `${AdminColors['500']} !important; `,
        },
        '&:hover': {
          background: `${AdminColors['300']} !important; `,
        },

      },
      actions: {
        '@global': {
          '.next-page': {
            marginLeft: 8,
            marginRight: 8,
          },
          '.previous-page': {
            marginRight: 8,
          },
        },
      },
    }
  }


})
export default theme



