import React from "react"
import { UserMenu, MenuItemLink,useLogout } from "react-admin"
import SettingsIcon from "@mui/icons-material/Settings"
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { MenuItem } from '@mui/material';
import { forwardRef } from 'react';

const MyLogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = () => logout();
  return (
      <MenuItem
          onClick={handleClick}
          ref={ref}
      >
          <ExitIcon />    Cerrar Sesión
      </MenuItem>
  );
});

const MyUserMenuView = ({ ...props }) => {


  return (
    <UserMenu label={"Mis Datos"} {...props}>
            

      <MenuItemLink
        to="/profile"
        primaryText={"Mis Datos"}
        leftIcon={<SettingsIcon />}
      />  
      <MyLogoutButton />
    </UserMenu>
  )
}

export default MyUserMenuView
