import create from "./create"
import edit from "./edit"
import list from "./list"
import show from "./show"
import icon from "./icon"

export default {
  list: list,
  create: create,
  edit: edit,
  icon: icon,
  show: show,
  options: { label: "Notificaciones" },
}
