import * as React from 'react';
import { useState } from 'react';

// @ts-ignore
import { useController } from 'react-hook-form';

import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';

export default function AmountInput({ name, label,defaultValue, ...props }) {
  const {
    field,
  
  
  } = useController({ name: name })
  var nevalue = field.value!=="" && field.value!==undefined  ? field.value : defaultValue  ;
  field.value = nevalue;
  const [value, setValue] = useState(nevalue)

  const onChangeValue = (e) => {
    field.onChange(e.target.value ??defaultValue) // data send back to hook form
    setValue(e.target.value ?? defaultValue) // UI state
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
        <FilledInput
          {...field}
          value={value}
          onChange={onChangeValue}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </div>
    </Box>
  )
}
