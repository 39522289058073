import React from 'react'
import { TextField, DateField, Show, SimpleShowLayout } from 'react-admin'
import ImageViewerField from 'helpers/fields/ImageViewerField';

const ProjectShow = props => (
  <Show title='Mostrando proyecto' {...props}>
    <SimpleShowLayout>
      <TextField source='urlKey' label='Url Key' />
      <TextField source='title' label='Título' />
      <TextField soruce='description' label='Descripción' />
      <ImageViewerField source='previewImage' label='Imagen de previsualizacion' />
      <ImageViewerField source='banner' label='Banner' />
      <ImageViewerField source='gallery' label='Galeria de Imagenes' />
      <TextField source='video' label='Enlace de youtube' type='video' fullWidth />
      <DateField source='publicationDate' label='Fecha de publicación' />
      <TextField source='id' />
    </SimpleShowLayout>
  </Show>
)
export default ProjectShow
