import React from "react";

import {
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

const validateTitle = [required(), minLength(2), maxLength(200)];

const BannerCreate = (props) => (
  <Create title="Crear información de banners" {...props}>
    <SimpleForm>
      <TextInput
        source="title"
        label="Título"
        fullWidth={true}
        validate={validateTitle}
        name={"title"}
      />
      <TextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        name={"description"}
      />
      <ImageInput source="image" label="Imagen" accept="image/*" name={"image"}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput
        source="link"
        label="Link"
        fullWidth={true}
        type="url"
        name={"link"}
      />
      <TextInput
        source="internalLink"
        label="Link interno del app"
        fullWidth={true}
        type="text"
        name={"internalLink"}
      />
      <BooleanInput source="active" label="Activo" name={"active"} />
      <TextInput
        source="priority"
        label="Prioridad"
        color="secondary"
        variant="outlined"
      />
      <BooleanInput
        source="requireAuth"
        label="Requiere Autenticación"
        name={"requireAuth"}
      />
    </SimpleForm>
  </Create>
);
export default BannerCreate;
