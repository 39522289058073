import React from "react"
import {
  TextInput,
  SimpleForm,
  Create,
  required,
  minLength,
  maxLength,
  BooleanInput,
  DateInput,
  ImageField,
  ImageInput,
} from "react-admin"

const validate = [required(), minLength(2), maxLength(100)]

const NotificationsCreate = (props) => (
  <Create title="Crear nueva campaña de notificación" {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Nombre de la campaña"
        fullWidth={true}
        validate={validate}
      />     
       <TextInput source="title" label="Titulo de la Notificación" fullWidth={true} />
      <DateInput source="date" label="Fecha" required />
      <TextInput source="message" label="Mensaje" fullWidth={true} />
      <ImageInput source="image" label="Icono de Notificación" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="link" label="Url de referencia" fullWidth={true} />
      <BooleanInput source="send" label="Enviar Notificación"></BooleanInput>
    </SimpleForm>
  </Create>
)
export default NotificationsCreate
