import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

const ListProduct = (props) => (
  <List {...props} title="Listado de categorias">
    <Datagrid>
      <TextField source="title" label="Titulo de la categoria" />
      <TextField source="description" label="Descripción" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
);

export default ListProduct;
