import React from 'react'
import {
  TextField,
  BooleanField,
  Show,
  SimpleShowLayout,
  UrlField,
} from 'react-admin'

const CommerceShow = (props) => (
  <Show title="Mostrando información del convenio con el comercio" {...props}>
    <SimpleShowLayout>
      {' '}
      <TextField source="id" />
      <TextField source="name" label="Nombre del convenio" />
      <TextField source="description " label="Descripción" />
      <UrlField source="url " label="Url" />
      <UrlField source="icon " label="Icono" />
      <BooleanField source="active" label="Activo" />
      <BooleanField source="requireAuth" label="Requiere auth" />

    </SimpleShowLayout>
  </Show>
)
export default CommerceShow
