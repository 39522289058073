import React from "react";
import {
  TextInput,
  SimpleForm,
  Create,
  ImageInput,
  ImageField,
  Labeled,
  SelectArrayInput,
  ReferenceInput,
} from "react-admin";

import { dateFormatter, dateParser } from "helpers/date";
import { RichTextInput } from "ra-input-rich-text";

const validateForm = (values) => {
  const { cover, previewImage, title } = values;
  const errors = {};

  if (!previewImage) {
    errors.previewImage = "Debes seleccionar una imagen";
  } else if (!cover) {
    errors.banner = "Debes seleccionar una imagen";
  } else if (title.length < 10 || title.length > 80) {
    errors.title = "El titulo debe tener entre 10 y 80 caracteres";
  }
  return errors;
};

const NewsCreate = (props) => (
  <Create title="Crear Noticias" {...props}>
    <SimpleForm validate={validateForm}>
      <TextInput source="urlKey" label="Url Key" fullWidth={true} required />
      <TextInput source="title" label="Titulo" fullWidth={true} required />
      <RichTextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        name={"description"}
        required
        multiline
      />
      <TextInput
        source="summary"
        label="Resumen"
        fullWidth={true}
        name={"summary"}
        multiline
      />
      <ImageInput
        source="cover"
        label="Portada"
        accept="image/*"
        maxSize={680000}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="previewImage"
        label="Imagen Previsualizacion"
        accept="image/*"
        maxSize={680000}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput
        source="gallery"
        label="Galeria de imagenes"
        accept="image/*"
        name={"gallery"}
        multiple={true}
        maxSize={680000}
        defaultValue={[]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <Labeled label="Fecha de publicacion">
        <TextInput
          source="publicationDate"
          label=""
          type="date"
          format={dateFormatter}
          parse={dateParser}
          color="secondary"
          variant="outlined"
          required
        />
      </Labeled>
      <TextInput source="video" label="Link de youtube" fullWidth={true} />

      <ReferenceInput source="externalUrl" reference="externalLink">
        <SelectArrayInput
          label="Enlaces Externos"
          optionText="title"
          fullWidth
          defaultValue={null}
        ></SelectArrayInput>
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
export default NewsCreate;
