import React from 'react'
import {
  TextField,
  BooleanField,
  Show,
  SimpleShowLayout,
  UrlField,
} from 'react-admin'
import ImageViewerField from '../../helpers/fields/ImageViewerField'

const BannerShow = (props) => (
  <Show title="Mostrando información del banner" {...props}>
    <SimpleShowLayout>
      <TextField source="title" label="Titulo del banner" />
      <TextField source="description" label="Descripción" />
      <UrlField source="link" label="Link" />
      <ImageViewerField source="image" label="Imagenes" />
      <BooleanField source="active" label="Activo" />
      <BooleanField source="requireAuth" label="Requiere autenticación" />
    </SimpleShowLayout>
  </Show>
)
export default BannerShow
