import React from 'react';

import {
  Create,
  maxLength,
  minLength,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const validateTitle = [required(), minLength(2), maxLength(100)];

const TeamCreate = (props) => (
  <Create title="Crear Equipo" {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Nombre Completo"
        fullWidth={true}
        validate={validateTitle}
      />
      <TextInput source="position" label="Cargo" fullWidth={true} required />
      <NumberInput source="level" label="Nivel"  />
      <TextInput source="contact.email" label="Email" fullWidth={true} />
      <TextInput source="contact.phone" label="Télefono" fullWidth={true} />
    </SimpleForm>
  </Create>
);
export default TeamCreate;
