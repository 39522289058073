import React from "react";

import {
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  Labeled,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { getST } from "provider/Storage";

const ProductCreate = (props) => {
  const role = getST("role");

  return (
    <Create title="Crear nuevo producto" {...props}>
      <SimpleForm>
        {role === "admin" ? (
          <Card variant="outlined" sx={{ minWidth: "100%" }}>
            <CardHeader title="Información del comercio" />
            <CardContent>
              <ReferenceInput
                source="organization"
                reference="organization"
                validate={required()}
                allowEmpty={true}
                filter={{ type: "commerce" }}
              >
                <SelectInput
                  label="Nombre"
                  optionText="name"
                  fullWidth
                  validate={required("Seleccione un comercio")}
                ></SelectInput>
              </ReferenceInput>
              <br></br>

              <Labeled label="Marcar este producto como destacado" fullWidth>
                <BooleanInput source="featured" label="" defaultValue={false} />
              </Labeled>
            </CardContent>
          </Card>
        ) : null}
        <br></br>

        <Card variant="outlined" sx={{ minWidth: "100%" }}>
          <CardHeader title="Información del producto" />
          <CardContent>
            <Labeled label="Título del producto" fullWidth>
              <TextInput source="title" label=" " fullWidth />
            </Labeled>
            <Labeled label="Descripción del producto" fullWidth>
              <TextInput source="description" label=" " fullWidth />
            </Labeled>
            <Labeled label="Imagenes del producto" fullWidth>
              <ImageInput source="images" label=" " accept="image/*" multiple>
                <ImageField source="src" title="title" />
              </ImageInput>
            </Labeled>

            <Labeled label="Categoria">
              <ReferenceArrayInput
                source="category"
                reference="productCategories"
              >
                <SelectArrayInput optionText="title" />
              </ReferenceArrayInput>
            </Labeled>

            <Labeled label="Precio del producto" fullWidth>
              <NumberInput source="price" label=" " />
            </Labeled>
            <Labeled label="Activo">
              <BooleanInput source="active" label="Activo" />
            </Labeled>
          </CardContent>
        </Card>
      </SimpleForm>
    </Create>
  );
};
export default ProductCreate;
