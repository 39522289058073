import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

const ListActivitiesCategories = (props) => (
  <List {...props} title="Listado de Categorias de Actividades">
    <Datagrid>
      <TextField source="name" label="Titulo de categoría de act." />
      <TextField source="description" label="Descripción" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
);
export default ListActivitiesCategories;
