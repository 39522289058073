import React from 'react';

import { TbDiscount } from 'react-icons/tb';

const icon = () => {
  return (
    <div>
      <TbDiscount size={24}></TbDiscount>
    </div>
  )
}
export default icon
