import React from "react";
import { RxActivityLog } from "react-icons/rx";
const icon = () => {
  return (
    <div>
      <RxActivityLog size={24}></RxActivityLog>
    </div>
  );
};
export default icon;
