import React from 'react';

import {
  BooleanInput,
  Edit,
  maxLength,
  minLength,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const validateTitle = [required(), minLength(2), maxLength(200)]
const CommerceEdit = (props) => (
  <Edit title="Editar convenio con el comercio." {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Nombre del convenio"
        fullWidth={true}
        validate={validateTitle}
      />
      <TextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        
      />
      <TextInput
        source="url"
        label="Url"
        fullWidth={true}
        type="url"
        
      />
      <TextInput
        source="icon"
        label="Icono"
        fullWidth={true}
        type="url"
        
      />
      <BooleanInput source="active" label="Activo" />
      <BooleanInput source="requireAuth" label="Requiere Auth" />
    </SimpleForm>
  </Edit>
)
export default CommerceEdit
