import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

const ListActivities = (props) => (
  <List {...props} title="Listado de Actividades">
    <Datagrid>
      <TextField source="title" label="Titulo de categorìa" />
      <TextField source="organizedBy" label="Organizado por" />
      <TextField source="description" label="Descripción" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
);
export default ListActivities;
