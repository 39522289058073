import "./style.scss";

import React, { useEffect, useState } from "react";

import { Labeled } from "react-admin";
import { useController } from "react-hook-form";
import PlacesAutocomplete, { getLatLng } from "react-places-autocomplete";

import TextField from "@mui/material/TextField";

import { geocodeByAddress, geocodeByPlaceLocation } from "./util";

function BoundedTextField({ name, label, ...props }) {
  const { fullWidth } = props;
  const {
    field,
    fieldState: { isTouched },
    formState: { isSubmitted },
  } = useController({ name: name });

  const [, setValue] = useState(field.value);

  const onChangeValue = (e) => {
    field.onChange(e.target.value ?? ""); // data send back to hook form
    setValue(e.target.value ?? ""); // UI state
  };

  return (
    <Labeled label={label} fullWidth={fullWidth ?? false}>
      <TextField
        {...field}
        onChange={onChangeValue}
        fullWidth={fullWidth ?? false}
        label={" "}
        color="secondary"
        focused
        disabled
        error={isTouched || isSubmitted}
        variant="filled"
      />
    </Labeled>
  );
}
export default function InputSearchPlace(props) {
  const { name } = props;

  const { field: streetNameField } = useController({
    name: name + ".streetName",
  });
  const { field: streetNumberField } = useController({
    name: name + '.streetNumber',
  })
  const { field: cityField } = useController({ name: name + ".city" });
  const { field: latitudeField } = useController({ name: name + ".latitude" });
  const { field: longitudeField } = useController({
    name: name + ".longitude",
  });

  const [streetName, setStreetName] = useState(streetNameField.value);
  const [streetNumber, setStreetNumber] = useState(streetNumberField.value)
  const [city, setCity] = useState(cityField.value);

  var latitude_ =
    latitudeField.value != null && latitudeField.value !== ""
      ? latitudeField.value
      : -34.1703131;
  var longitude_ =
    longitudeField.value != null && longitudeField.value !== ""
      ? longitudeField.value
      : -70.7406259;

  const [latitude, setLatitude] = useState(parseFloat(latitude_));
  const [longitude, setLongitude] = useState(parseFloat(longitude_));

  const [address, setAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [componentForm] = useState({
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name",
    administrative_area_level_2: "short_name",
    establishment: "long_name",
    park: "long_name",
    point_of_interest: "long_name",
    stadium: "long_name",
  });

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      fillInAddress(results[0]);
      getLatLng(results[0]);
    });
  };
  const fillInAddress = (place, site) => {
    try {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
          if (addressType === "street_number") {
            let cadena = place.address_components[i][componentForm[addressType]];
            let aux = cadena.split(",");
            setStreetNumber(aux[0])
            streetNumberField.onChange(aux[0])
          } else if (addressType === "route") {
            setStreetName(
              place.address_components[i][componentForm[addressType]]
            );
            streetNameField.onChange(
              place.address_components[i][componentForm[addressType]]
            );
          } else if (
            addressType === "establishment" ||
            addressType === "park" ||
            addressType === "point_of_interest" ||
            addressType === "stadium"
          ) {
            setStreetName(
              place.address_components[i][componentForm[addressType]] +
                " - " +
                streetName
            );
            streetNameField.onChange(
              place.address_components[i][componentForm[addressType]] +
                " - " +
                streetName
            );
          } else if (addressType === "locality") {
            setCity(place.address_components[i][componentForm[addressType]]);
            cityField.onChange(
              place.address_components[i][componentForm[addressType]]
            );
          }
        }
      }
      var latitude = place.geometry.location.lat();
      var longitude = place.geometry.location.lng();
      setLatitude(latitude);
      latitudeField.onChange(latitude);
      setLongitude(longitude);
      longitudeField.onChange(longitude);
      iniciarMap();
    } catch (e) {
      setErrorMessage("No se pudo obtener la dirección");
    }
  };

  const update = (e) => {
    const LatLng = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    geocodeByPlaceLocation(LatLng).then((res) => {
      fillInAddress(res[0], "marker");
    });
  };
  useEffect(() => {
    iniciarMap();
  });
  const iniciarMap = () => {
    var map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: latitude, lng: longitude },
      zoom: 17,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_CENTER,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_CENTER,
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_TOP,
      },
      fullscreenControl: true,
    });
    var position = { lat: latitude, lng: longitude };

    var marker = new window.google.maps.Marker({
      position: position,
      map: map,
      draggable: true,
    });

    window.google.maps.event.addListener(marker, "dragend", update);
  };

  return (
    <div>
      {" "}
      <Labeled label="Buscar Dirección">
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <TextField
                color="secondary"
                className="location-search-input "
                fullWidth={true}
                {...getInputProps({
                  placeholder: "Buscar lugar...",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Labeled>
      {errorMessage.length > 0 && (
        <div className="error-message">{errorMessage}</div>
      )}
      <div>
        <div id="map" className="map">
          mapa
        </div>
        <div id="infowindow-content">
          <img src="" width="16" height="16" id="place-icon" alt="" />
          <div id="place-name" className="title" />
          <div id="place-address" />
        </div>
      </div>
      <div>
        <div className="divInput FormInput-input-55">
          <BoundedTextField
            name={name + ".streetName"}
            label="Calle"
            value={streetName}
            onChange={streetNameField.onChange}
            fullWidth
          />
        </div>
        <div className="divInput FormInput-input-55">
          <BoundedTextField
              name={name + '.streetNumber'}
              label="Número"
              fullWidth
              value={streetNumber}
              onChange={streetNumberField.onChange}
          />
        </div>
        <div className="divInput FormInput-input-55">
          <BoundedTextField
            name={name + ".city"}
            label="Ciudad"
            value={city}
            onChange={cityField.onChange}
          />
        </div>
        <div className="divInput FormInput-input-55 oculto">
          <BoundedTextField
            name={name + ".longitude"}
            label="Longitud"
            value={longitude}
            onChange={longitudeField.onChange}
          />
        </div>
        <div className="divInput FormInput-input-55 oculto">
          <BoundedTextField
            name={name + ".latitude"}
            label="Latitud"
            value={latitude}
            onChange={latitudeField.onChange}
          />
        </div>
      </div>
    </div>
  );
}
