import React from 'react'
import {
  TextInput,
  Edit,
  SimpleForm,
  ImageInput,
  ImageField,
} from 'react-admin'

const ExternalLinkEdit = props => (
  <Edit {...props} title='Editando Enlace Externo'>
    <SimpleForm>
      <TextInput source='title' label='Titulo' fullWidth={true} />
      <TextInput source='url' label='URL' fullWidth={true} />
      <ImageInput source='image' label='Imagen' accept='image/*'>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Edit>
)

export default ExternalLinkEdit