import React from "react";

import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const CreateActivitiesCategories = (props) => (
  <Create title="Crear Categorias de Actividades" {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nombre" fullWidth={true} name={"name"} type="text" required/>
      <TextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        name={"description"}
        type="text"
        required
      />
      <ReferenceInput
          source="district"
          reference="district"
      >
        <SelectInput
            label="Comuna"
            optionText="name"
            fullWidth
            defaultValue={null}
        ></SelectInput>

      </ReferenceInput>
    </SimpleForm>
  </Create>
);
export default CreateActivitiesCategories;
