import React from "react";

import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  SelectArrayInput
} from 'react-admin';

import {RichTextInput} from "ra-input-rich-text";


const CdirProjectsCategoriesCreate = (props) => (
  <Create title="Crear información de Proyectos Cdir" {...props}>
    <SimpleForm>
      <TextInput source='urlKey' label='Url Key' fullWidth={true}/>
      <TextInput source='title' label='Titulo' fullWidth={true}  />
      <RichTextInput
        source="description"
        label="Descripción"
        fullWidth={true}
        name={"description"}
        required
        multiline
      />
      <ImageInput source="coverMobile" label="Portada Movil" accept="image/*" name={"coverMobile"}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput source="coverDesktop" label="Portada Web" accept="image/*" name={"coverDesktop"}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput source="categoryImage" label="Portada Principal" accept="image/*" name={"categoryImage"}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceInput source="projects" reference="cdir/projects">
        <SelectArrayInput
          label="Proyectos"
          optionText="title"
          fullWidth
          defaultValue={null}
        ></SelectArrayInput>
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default CdirProjectsCategoriesCreate;