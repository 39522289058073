import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

const ListDistricts = (props) => (
  <List {...props} title="Listado de comunas">
    <Datagrid>
      <TextField source="name" label="Nombre de comuna" />
      <TextField source="description" label="Descripción" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
);
export default ListDistricts;
